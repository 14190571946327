import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-cluster3',
  templateUrl: './select-cluster3.component.html',
  styleUrls: ['./select-cluster3.component.scss']
})
export class SelectCluster3Component implements OnInit, OnDestroy {

  public items: any[] = [
    { name: 'Sin Filtro', value: null }
  ];
  public cluster: any = [];
  public ngxDisabled = true;
  idGroup$: Subscription;
  idGroup;
  selectedClusters;
  selectedClusters$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private gasprecioService: GasprecioService,
    private sharedService: SharedService
  ) {
    this.idGroup$ = sharedService.sharedGroup.subscribe((idGroup: string) => {
      if (idGroup !== this.idGroup) {
        this.idGroup = idGroup;
        this.getClusters();
      }
    });

    this.selectedClusters$ = this.sharedService.sharedSelectedComplementClusters3.subscribe(selectedClusters => {
      if (selectedClusters !== this.selectedClusters) {
        this.selectedClusters = selectedClusters;
      }
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnInit(): void { }

  ngOnDestroy() {
    this.idGroup$.unsubscribe();
    this.selectedClusters$.unsubscribe();
  }

  getClusters() {
    this.items = [
      { name: 'Sin Filtro', value: null }
    ];
    this.gasprecioService.getClusters(this.idGroup, 'clusters_3').subscribe((data: any) => {
      data.map((group) => {
        this.items.push({ name: group['name'], value: group['_id'] });
      });
      this.ngxDisabled = false;
    });
  }

  changeCluster(event: any) {
    this.selectedClusters = event;
    this.sharedService.nextSelectedComplementClusters3 = this.selectedClusters;
  }
}
