import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatLargeNumber'
})
export class FormatLargeNumberPipe implements PipeTransform {

  transform(value: string): string {
    const num = parseFloat(value);
    if (isNaN(num)) return value;

    const [integerPart, decimalPart] = num.toString().split('.');

    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    if (decimalPart && decimalPart.length > 3) {
      return `${formattedIntegerPart}.${decimalPart}`;
    }

    return formattedIntegerPart;
  }

}
