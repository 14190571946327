import localeEsAr from '@angular/common/locales/es-AR';
import { AngularDraggableModule } from 'angular2-draggable';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BasicAuthInterceptor } from './helpers/interceptors/basic-auth.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragulaModule } from 'ng2-dragula';
import { ErrorInterceptor } from './helpers/interceptors/error.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LoginModule } from './pages/login/login.module';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { RecoverPasswordModule } from './pages/recover-password/recover-password.module';
import { RegisterComponent } from './pages/register/register.component';
import { SetPasswordModule } from './pages/set-password/set-password.module';
import { SharedModule } from './modules/shared/shared.module';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { UploadPricesModule } from './pages/upload-prices/upload-prices.module';
import { VerifyAccountModule } from './pages/verify-account/verify-account.module';
import { registerLocaleData } from '@angular/common';
import { NgxSelectModule } from 'ngx-select-ex';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { LoginPricesModule } from './pages/login-prices/login-prices.module';
import { CookieService } from 'ngx-cookie-service';
import { HighchartsChartModule } from 'highcharts-angular';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
registerLocaleData(localeEsAr, 'es');

@NgModule({
  declarations: [
    AppComponent,
    RegisterComponent,
    NotFoundComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    LoginModule,
    LoginPricesModule,
    UploadPricesModule,
    VerifyAccountModule,
    RecoverPasswordModule,
    SetPasswordModule,
    HttpClientModule,
    AngularDraggableModule,
    SweetAlert2Module.forRoot(),
    DragulaModule.forRoot(),
    NgxSelectModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxDaterangepickerMd.forRoot(),
    HighchartsChartModule,
    ModalModule.forRoot(),
    FontAwesomeModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    CookieService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
