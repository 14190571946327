<div class="row" style="margin:10px;" *ngIf="reqst === 0">
    <app-table-loader style="width: 100%;"></app-table-loader>
</div>
<table *ngIf="reqst === 1">
    <thead>
        <tr>
            <th style="width: 27%;padding: 0 1px !important;" class="text-right">
                <span style="font-size: 15px;text-decoration: underline;margin-right: 20px;">
                    Últimos {{daysAgo}} días
                </span>
            </th>
            <th *ngFor="let row of rowsRecord"
                [ngClass]="{ 'low':market === 'low' && row.flag_actual !== undefined, 'medium-l':market === 'medium_low' && row.flag_actual !== undefined,'medium-h':market === 'medium_high' && row.flag_actual !== undefined,'high':market === 'high' && row.flag_actual !== undefined, 'border-all': !row.flag_actual}"
                [ngStyle]="{ 'width': rowsLength + '%' }">
                <span *ngIf="row.level === 0">Nula</span>
                <span *ngIf="row.level === 1">Poca</span>
                <span *ngIf="row.level === 2">Media</span>
                <span *ngIf="row.level === 3">Mucha</span>
            </th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td class="right-text">
                <span class="right-text">
                    # días
                    <epy-tooltip placement="top" style="width: 18px;display: inline-block;text-align: left;">
                        <span class="difference" slot="trigger">
                            <i class="fa fa-info-circle info" aria-hidden="true"></i>
                        </span>
                        # de observaciones de los últimos {{daysAgo}} días.
                    </epy-tooltip>
                </span>
            </td>
            <td *ngFor="let row of rowsRecord; let index = index"
                [ngClass]="{ 'low-sides':market === 'low' && row.flag_actual !== undefined, 'medium-l-sides':market === 'medium_low' && row.flag_actual !== undefined,'medium-h-sides':market === 'medium_high' && row.flag_actual !== undefined,'high-sides':market === 'high' && row.flag_actual !== undefined, 'border-sides': !row.flag_actual }">
                <span style="display: flex;justify-content: center;align-items: center;column-gap: 10px;">
                    {{row.n_obs}}
                    <p title="Pocas observaciones" class="red" style="margin-bottom: 0;" [attr.data-letters]="'red'"
                        *ngIf="row.flag_obs === 1"></p>
                </span>
            </td>
        </tr>
        <tr>
            <td class="right-text">
                <span class="right-text">
                    Precio de Venta
                    <epy-tooltip placement="top" style="width: 18px;display: inline-block;text-align: left;">
                        <span class="difference" slot="trigger">
                            <i class="fa fa-info-circle info" aria-hidden="true"></i>
                        </span>
                        Precio de venta promedio diario reportado a la CRE por estación de los últimos {{daysAgo}} días.
                    </epy-tooltip>
                </span>
            </td>
            <td *ngFor="let row of rowsRecord; let index = index"
                [ngClass]="{ 'low-sides':market === 'low' && row.flag_actual !== undefined, 'medium-l-sides':market === 'medium_low' && row.flag_actual !== undefined,'medium-h-sides':market === 'medium_high' && row.flag_actual !== undefined,'high-sides':market === 'high' && row.flag_actual !== undefined, 'border-sides': !row.flag_actual }">
                <span *ngIf="row.selling_price !== '-'">
                    $ {{row.selling_price| number : '1.2-2'}}
                </span>
                <span *ngIf="row.selling_price === '-'">
                    {{row.selling_price}}
                </span>
            </td>
        </tr>

        <tr>
            <td class="right-text">
                <span class="right-text">
                    # competidores con mín
                    <epy-tooltip placement="top" style="width: 18px;display: inline-block;text-align: left;">
                        <span class="difference" slot="trigger">
                            <i class="fa fa-info-circle info" aria-hidden="true"></i>
                        </span>
                        # de observaciones de los últimos {{daysAgo}} días.
                    </epy-tooltip>
                </span>
            </td>
            <td *ngFor="let row of rowsRecord; let index = index"
                [ngClass]="{ 'low-sides':market === 'low' && row.flag_actual !== undefined, 'medium-l-sides':market === 'medium_low' && row.flag_actual !== undefined,'medium-h-sides':market === 'medium_high' && row.flag_actual !== undefined,'high-sides':market === 'high' && row.flag_actual !== undefined, 'border-sides': !row.flag_actual }">
                <span style="display: flex;justify-content: center;align-items: center;column-gap: 10px;">
                    {{row.n_comp_min}}
                </span>
            </td>
        </tr>
        
        <tr style="background: #002060;">
            <td class="right-text">
                <span class="right-text" style="color: white !important;">
                    <strong>
                        Dif Precio vs Px Mín.
                    </strong>
                    <epy-tooltip placement="top" style="width: 18px;display: inline-block;text-align: left;">
                        <span class="difference" slot="trigger">
                            <i class="fa fa-info-circle info" style="color: white !important;" aria-hidden="true"></i>
                        </span>
                        Promedio Diario de la diferencia de Precio de venta de tu estación vs tu competencia
                    </epy-tooltip>
                </span>
            </td>
            <td *ngFor="let row of rowsRecord; let index = index"
                style="border: 1px solid black !important;border-bottom: none !important;border-top: none !important;font-weight: bold;color: white !important;">
                <span *ngIf="row.dif_p_min !== '-'">
                    <strong>
                        $ {{row.dif_p_min| number : '1.2-2'}}
                    </strong>
                </span>
                <span *ngIf="row.dif_p_min === '-'">
                    <strong>
                        {{row.dif_p_min}}
                    </strong>
                </span>
            </td>
        </tr>

        <tr style="background: #002060;">
            <td class="right-text">
                <span class="right-text" style="color: white !important;">
                    <strong>
                        Indice de Precios
                    </strong>
                    <epy-tooltip placement="top" style="width: 18px;display: inline-block;text-align: left;">
                        <span class="difference" slot="trigger">
                            <i class="fa fa-info-circle info" style="color: white !important;" aria-hidden="true"></i>
                        </span>
                        El Indice es el Precio de Tu Estación dividido entre el Precio de tus Competidores.<br> Si es <
                            1.0 significa que tu precio es menor, mientras que si es> 1.0 tu precio es más alto que el
                            de tus competidores.
                    </epy-tooltip>
                </span>
            </td>
            <td *ngFor="let row of rowsRecord; let index = index"
                style="border: 1px solid black !important;border-bottom: none !important;border-top: none !important;font-weight: bold;color: white !important;">
                <span *ngIf="row.quotient !== '-'">
                    <strong>
                        {{row.quotient| number : '1.3-3'}}
                    </strong>
                </span>
                <span *ngIf="row.quotient === '-'">
                    <strong>
                        {{row.quotient}}
                    </strong>
                </span>
            </td>
        </tr>
        <tr>
            <td class="right-text">
                <span class="right-text">
                    Margen Promedio
                    <epy-tooltip placement="top" style="width: 18px;display: inline-block;text-align: left;">
                        <span class="difference" slot="trigger">
                            <i class="fa fa-info-circle info" aria-hidden="true"></i>
                        </span>
                        Promedio de margen de ganancia diario calculado por estación (Precio de Venta vs Precio de
                        Compra) de los últimos {{daysAgo}} días.
                    </epy-tooltip>
                </span>
            </td>
            <td *ngFor="let row of rowsRecord; let index = index"
                [ngClass]="{ 'low-sides':market === 'low' && row.flag_actual !== undefined, 'medium-l-sides':market === 'medium_low' && row.flag_actual !== undefined,'medium-h-sides':market === 'medium_high' && row.flag_actual !== undefined,'high-sides':market === 'high' && row.flag_actual !== undefined, 'border-sides': !row.flag_actual }">
                <span *ngIf="row.margin !== '-'">
                    $ {{row.margin| number : '1.2-2'}}
                </span>
                <span *ngIf="row.margin === '-'">
                    {{row.margin}}
                </span>
            </td>
        </tr>
        <tr>
            <td class="right-text">
                <span class="right-text">
                    Venta Litros
                    <epy-tooltip placement="top" style="width: 18px;display: inline-block;text-align: left;">
                        <span class="difference" slot="trigger">
                            <i class="fa fa-info-circle info" aria-hidden="true"></i>
                        </span>
                        Tendencia general de los últimos {{daysAgo}} días.
                    </epy-tooltip>
                </span>
            </td>
            <td *ngFor="let row of rowsRecord; let index = index"
                [ngClass]="{ 'low-sides':market === 'low' && row.flag_actual !== undefined, 'medium-l-sides':market === 'medium_low' && row.flag_actual !== undefined,'medium-h-sides':market === 'medium_high' && row.flag_actual !== undefined,'high-sides':market === 'high' && row.flag_actual !== undefined, 'border-sides': !row.flag_actual }">
                <span *ngIf="row.sales !== '-'">
                    {{row.sales| number : '1.0-0'}}
                </span>
                <span *ngIf="row.sales === '-'">
                    {{row.sales}}
                </span>
            </td>
        </tr>
        <tr>
            <td class="right-text">
                <span class="right-text">
                    Ganancia Promedio
                    <epy-tooltip placement="top" style="width: 18px;display: inline-block;text-align: left;">
                        <span class="difference" slot="trigger">
                            <i class="fa fa-info-circle info" aria-hidden="true"></i>
                        </span>
                        Promedio de volumen de venta en litros multiplicado en margen bruto de los últimos {{daysAgo}} días.
                    </epy-tooltip>
                </span>
            </td>
            <td *ngFor="let row of rowsRecord; let index = index"
                [ngClass]="{ 'low-bottom':market === 'low' && row.flag_actual !== undefined, 'medium-l-bottom':market === 'medium_low' && row.flag_actual !== undefined,'medium-h-bottom':market === 'medium_high' && row.flag_actual !== undefined,'high-bottom':market === 'high' && row.flag_actual !== undefined, 'border-bottom': !row.flag_actual }">
                <span *ngIf="row.earning !== '-'">
                    $ {{row.earning| number : '1.0-0'}}
                </span>
                <span *ngIf="row.earning === '-'">
                    {{row.earning}}
                </span>
            </td>
        </tr>
        <tr style="height:10px">
            <td *ngFor="let row of rowsRecord; let index = index"
                style="height: 10px !important;color: white !important;font-size: 1px !important;">
                &nbsp;
            </td>
        </tr>
        <tr>
            <td class="right-text">
                <span class="right-text">
                    <i class="fa-solid fa-star icon"></i> Cambio Venta lts
                    <epy-tooltip placement="top" style="width: 18px;display: inline-block;text-align: left;">
                        <span class="difference" slot="trigger">
                            <i class="fa fa-info-circle info" aria-hidden="true"></i>
                        </span>
                        Diferencia de Litros Vendidos Promedio de la zona actual con respecto a las otras zonas
                    </epy-tooltip>
                </span>
            </td>
            <td *ngFor="let row of rowsRecord; let index = index" style="border-bottom: none !important;"
                [ngClass]="{ 'low':market === 'low' && row.flag_actual !== undefined, 'medium-l':market === 'medium_low' && row.flag_actual !== undefined,'medium-h':market === 'medium_high' && row.flag_actual !== undefined,'high':market === 'high' && row.flag_actual !== undefined, 'border-top': !row.flag_actual}">
                <span *ngIf="row.sales_change !== '-'">
                    <span *ngIf="row.sales_change > 0">+</span>{{row.sales_change| number : '1.0-0'}}
                </span>
                <span *ngIf="row.sales_change === '-'">
                    {{row.sales_change}}
                </span>
            </td>
        </tr>

        <tr>
            <td class="right-text">
                <span class="right-text">
                    Cambio Venta %
                    <epy-tooltip placement="top" style="width: 18px;display: inline-block;text-align: left;">
                        <span class="difference" slot="trigger">
                            <i class="fa fa-info-circle info" aria-hidden="true"></i>
                        </span>
                        Diferencia de % de Litros Vendidos Promedio de la zona actual con respecto a las otras zonas
                    </epy-tooltip>
                </span>
            </td>
            <td *ngFor="let row of rowsRecord; let index = index" style="border-top: none !important;"
                [ngClass]="{ 'low':market === 'low' && row.flag_actual !== undefined, 'medium-l':market === 'medium_low' && row.flag_actual !== undefined,'medium-h':market === 'medium_high' && row.flag_actual !== undefined,'high':market === 'high' && row.flag_actual !== undefined, 'border-bottom': !row.flag_actual}">
                <span *ngIf="row.sales_pct !== '-'">
                    {{row.sales_pct| number : '1.0-0'}} %
                </span>
                <span *ngIf="row.sales_pct === '-'">
                    {{row.sales_pct}}
                </span>
            </td>
        </tr>
    </tbody>
</table>

<table *ngIf="reqst === 1" style="margin-top: 10px;">
    <thead>
        <tr>
            <th style="width: 27%;padding: 0 1px !important;" class="text-right">
                <span style="font-size: 15px;text-decoration: underline;margin-right: 20px;">
                    Actual
                </span>
            </th>
            <th *ngFor="let row of rowsCurrent"
                [ngClass]="{ 'low':market === 'low' && row.flag_actual !== undefined, 'medium-l':market === 'medium_low' && row.flag_actual !== undefined,'medium-h':market === 'medium_high' && row.flag_actual !== undefined,'high':market === 'high' && row.flag_actual !== undefined, 'border-all': !row.flag_actual}"
                [ngStyle]="{'width': rowsLength + '%' }">
                <span *ngIf="row.level === 0">Nula</span>
                <span *ngIf="row.level === 1">Poca</span>
                <span *ngIf="row.level === 2">Media</span>
                <span *ngIf="row.level === 3">Mucha</span>
                
            </th>
        </tr>
    </thead>
    <tbody>

        <tr>
            <td class="right-text">
                <span class="right-text">
                    Precio de Venta
                    <epy-tooltip placement="top" style="width: 18px;display: inline-block;text-align: left;">
                        <span class="difference" slot="trigger">
                            <i class="fa fa-info-circle info" aria-hidden="true"></i>
                        </span>
                        Precio de venta diario reportado a la CRE por estación.
                    </epy-tooltip>
                </span>
            </td>
            <td *ngFor="let row of rowsCurrent; let index = index"
                [ngClass]="{ 'low-sides':market === 'low' && row.flag_actual !== undefined, 'medium-l-sides':market === 'medium_low' && row.flag_actual !== undefined,'medium-h-sides':market === 'medium_high' && row.flag_actual !== undefined,'high-sides':market === 'high' && row.flag_actual !== undefined, 'border-sides': !row.flag_actual }">
                <span *ngIf="row.selling_price !== '-'">
                    $ {{row.selling_price| number : '1.2-2'}}
                </span>
                <span *ngIf="row.selling_price === '-'">
                    {{row.selling_price}}
                </span>
            </td>
        </tr>
        <tr>
            <td class="right-text">
                <span class="right-text">
                    <i class="fa-solid fa-star icon"></i> Cambio de Precio
                    <epy-tooltip placement="top" style="width: 18px;display: inline-block;text-align: left;">
                        <span class="difference" slot="trigger">
                            <i class="fa fa-info-circle info" aria-hidden="true"></i>
                        </span>
                        Promedio de litros diarios vendidos por estación.
                    </epy-tooltip>
                </span>
            </td>
            <td *ngFor="let row of rowsCurrent; let index = index"
                style="border-top: none !important;border-bottom: none !important;"
                [ngClass]="{ 'low':market === 'low' && row.flag_actual !== undefined, 'medium-l':market === 'medium_low' && row.flag_actual !== undefined,'medium-h':market === 'medium_high' && row.flag_actual !== undefined,'high':market === 'high' && row.flag_actual !== undefined, 'border-sides': !row.flag_actual}">
                <span *ngIf="row.price_change !== '-'">
                    $ {{row.price_change| number : '1.2-2'}}
                </span>
                <span *ngIf="row.price_change === '-'">
                    {{row.price_change}}
                </span>
            </td>
        </tr>
        <tr style="background: #002060;">
            <td class="right-text">
                <span class="right-text" style="color: white !important;">
                    <strong>
                        Dif Precio vs Px Mín.
                    </strong>
                    <epy-tooltip placement="top"
                        style="width: 18px;display: inline-block;text-align: left;color: white !important;">
                        <span class="difference" slot="trigger">
                            <i class="fa fa-info-circle info" style="color: white !important;" aria-hidden="true"></i>
                        </span>
                        Promedio Diario de la diferencia de Precio de venta de tu estación vs tu competencia
                    </epy-tooltip>
                </span>
            </td>
            <td *ngFor="let row of rowsCurrent; let index = index"
                style="border: 1px solid black !important;border-bottom: none !important;border-top: none !important;color: white !important;">
                <span *ngIf="row.dif_p_min !== '-'">
                    <strong>
                        $ {{row.dif_p_min| number : '1.2-2'}}
                    </strong>
                </span>
                <span *ngIf="row.dif_p_min === '-'">
                    <strong>
                        {{row.dif_p_min}}
                    </strong>
                </span>
            </td>
        </tr>

        <tr style="background: #002060;">
            <td class="right-text">
                <span class="right-text" style="color: white !important;">
                    <strong>
                        Indice de Precios
                    </strong>
                    <epy-tooltip placement="top"
                        style="width: 18px;display: inline-block;text-align: left;color: white !important;">
                        <span class="difference" slot="trigger">
                            <i class="fa fa-info-circle info" style="color: white !important;" aria-hidden="true"></i>
                        </span>
                        El Indice es el Precio de Tu Estación dividido entre el Precio de tus Competidores.<br> Si es <
                            1.0 significa que tu precio es menor, mientras que si es> 1.0 tu precio es más alto que el
                            de tus competidores.
                    </epy-tooltip>
                </span>
            </td>
            <td *ngFor="let row of rowsCurrent; let index = index"
                style="border: 1px solid black !important;border-bottom: none !important;border-top: none !important;color: white !important;">
                <span *ngIf="row.quotient !== '-'">
                    <strong>
                        {{row.quotient| number : '1.3-3'}}
                    </strong>
                </span>
                <span *ngIf="row.quotient === '-'">
                    <strong>
                        {{row.quotient}}
                    </strong>
                </span>
            </td>
        </tr>


        <tr>
            <td class="right-text">
                <span class="right-text">
                    Zona de Mercado
                    <epy-tooltip placement="top" style="width: 18px;display: inline-block;text-align: left;">
                        <span class="difference" slot="trigger">
                            <i class="fa fa-info-circle info" aria-hidden="true"></i>
                        </span>
                        Zona de Mercado
                    </epy-tooltip>
                </span>
            </td>
            <td *ngFor="let row of rowsCurrent; let index = index"
                [ngClass]="{ 'low-sides':market === 'low' && row.flag_actual !== undefined, 'medium-l-sides':market === 'medium_low' && row.flag_actual !== undefined,'medium-h-sides':market === 'medium_high' && row.flag_actual !== undefined,'high-sides':market === 'high' && row.flag_actual !== undefined, 'border-sides': !row.flag_actual }">
                <span *ngIf="row.market === 0">Baja</span>
                <span *ngIf="row.market === 1">Media Baja</span>
                <span *ngIf="row.market === 2">Media Alta</span>
                <span *ngIf="row.market === 3">Alta</span>
            </td>
        </tr>
        <tr>
            <td class="right-text">
                <span class="right-text">
                    Venta litros
                    <epy-tooltip placement="top" style="width: 18px;display: inline-block;text-align: left;">
                        <span class="difference" slot="trigger">
                            <i class="fa fa-info-circle info" aria-hidden="true"></i>
                        </span>
                        Litros vendidos por estación.
                    </epy-tooltip>
                </span>
            </td>
            <td *ngFor="let row of rowsCurrent; let index = index"
                [ngClass]="{ 'low-sides':market === 'low' && row.flag_actual !== undefined, 'medium-l-sides':market === 'medium_low' && row.flag_actual !== undefined,'medium-h-sides':market === 'medium_high' && row.flag_actual !== undefined,'high-sides':market === 'high' && row.flag_actual !== undefined, 'border-sides': !row.flag_actual }">
                <span *ngIf="row.sales !== '-'">
                    {{row.sales| number : '1.0-0'}}
                </span>
                <span *ngIf="row.sales === '-'">
                    {{row.sales}}
                </span>
            </td>
        </tr>

        <tr style="background: black;">
            <td class="right-text">
                <span class="right-text" style="color: white !important;font-weight: bold;">
                    Margen Actual
                    <epy-tooltip placement="top" style="width: 18px;display: inline-block;text-align: left;">
                        <span class="difference" slot="trigger">
                            <i class="fa fa-info-circle info" aria-hidden="true" style="color: white !important;"></i>
                        </span>
                        Margen Actual de hoy por estación.
                    </epy-tooltip>
                </span>
            </td>
            <td *ngFor="let row of rowsCurrent; let index = index" 
                [ngClass]="{ 'low-sides':market === 'low' && row.flag_actual !== undefined, 'medium-l-sides':market === 'medium_low' && row.flag_actual !== undefined,'medium-h-sides':market === 'medium_high' && row.flag_actual !== undefined,'high-sides':market === 'high' && row.flag_actual !== undefined, 'border-sides': !row.flag_actual }">
                <span *ngIf="row.margin !== '-'" style="color: white !important;font-weight: bold;">
                    $ {{row.margin| number : '1.2-2'}}
                </span>
                <span *ngIf="row.margin === '-'" style="color: white !important;font-weight: bold;">
                    {{row.margin}}
                </span>
            </td>
        </tr>

        <tr>
            <td class="right-text">
                <span class="right-text">
                    Ganancia Promedio
                    <epy-tooltip placement="top" style="width: 18px;display: inline-block;text-align: left;">
                        <span class="difference" slot="trigger">
                            <i class="fa fa-info-circle info" aria-hidden="true"></i>
                        </span>
                        Promedio de volumen de venta en litros multiplicado en margen bruto de los últimos {{daysAgo}} días.
                    </epy-tooltip>
                </span>
            </td>
            <td *ngFor="let row of rowsCurrent; let index = index"
                [ngClass]="{ 'low-bottom':market === 'low' && row.flag_actual !== undefined, 'medium-l-bottom':market === 'medium_low' && row.flag_actual !== undefined,'medium-h-bottom':market === 'medium_high' && row.flag_actual !== undefined,'high-bottom':market === 'high' && row.flag_actual !== undefined, 'border-bottom': !row.flag_actual, 'max-earning': row.flag_max_earning }">
                <span *ngIf="row.earning !== '-'">
                    $ {{row.earning| number : '1.0-0'}}
                </span>
                <span *ngIf="row.earning === '-'">
                    {{row.earning}}
                </span>
            </td>
        </tr>

    </tbody>
</table>
<div class="row" style="margin-top: 10px;">
    <div class="circles">
        <div class="rectangle {{market}}"></div>
        <span>
            Distancia actual
        </span>
        <p title="Pocas observaciones" class="red" style="margin-bottom: 0;" [attr.data-letters]="'red'"></p>
        <span>
            Pocas observaciones
        </span>
        <div class="rectangle"></div>
        <span>
            Máxima ganancia actual
        </span>
    </div>
</div>