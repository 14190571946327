import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-multiselect-variables',
  templateUrl: './multiselect-variables.component.html',
  styleUrls: ['./multiselect-variables.component.scss']
})
export class MultiselectVariablesComponent implements  OnDestroy {

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Todas',
    unSelectAllText: 'Todas',
    itemsShowLimit: 1,
    allowSearchFilter: true,
    clearSearchFilter: true,
    searchPlaceholderText: 'Buscar...',
    maxHeight: 140
  };
  url: string;
  public idGroup: any;
  rol;
  idGroup$: Subscription;

  constructor(
    private sharedService: SharedService,
    private gasprecioService: GasprecioService
  ) {
    this.rol = localStorage.getItem('rol');
    this.idGroup = (this.rol === 'Dios') ? localStorage.getItem('group') || '60afa99655f4ee369d1df409' : localStorage.getItem('group');

    this.idGroup$ = sharedService.sharedGroup.subscribe((idGroup: string) => {
      if (idGroup !== this.idGroup) {
        this.idGroup = idGroup;
        if (this.idGroup === '615efe30b318ce63b8971986') {
          this.getZones();
        } else {
          this.getReasons();
        }
      }
    });

    if (this.idGroup === '615efe30b318ce63b8971986') {
      this.getZones();
    } else {
      this.getReasons();
    }
  }

  ngOnDestroy() {
    this.idGroup$.unsubscribe();
  }

  getZones() {
    const items = [
      { item_id: 'regular', item_text: 'Regular' },
      { item_id: 'premium', item_text: 'Premium' },
      { item_id: 'diesel', item_text: 'Diesel' },
      { item_id: 'green', item_text: 'Verde' },
      { item_id: 'yellow', item_text: 'Amarillo' },
      { item_id: 'red', item_text: 'Rojo' }
    ];

    this.gasprecioService.getReasons().subscribe((data: any) => {
      const reasons: any = data;
      reasons.forEach((element: any, index) => {
        const pos = index + 1;
        items.push({ item_id: element._id, item_text: pos + '.- ' + element.heading });
      });

      this.selectedItems = [{ item_id: 'regular', item_text: 'Regular' }];
    });

    this.gasprecioService.getZones(this.idGroup).subscribe((data: any) => {
      const zones: any = data;
      zones.forEach((element: any) => {
        if (element.zone_id !== '6178671b46818a57510e921b' && element.zone_id !== '6178671b46818a57510e921a') {
          items.push({ item_id: element['zone_id'], item_text: this.gasprecioService.capitalizeFirstLetter(element['name']) });
        }
      });

      this.dropdownList = items;
    });
  }

  getReasons() {
    const items = [
      { item_id: 'regular', item_text: 'Regular' },
      { item_id: 'premium', item_text: 'Premium' },
      { item_id: 'diesel', item_text: 'Diesel' },
      { item_id: 'green', item_text: 'Verde' },
      { item_id: 'yellow', item_text: 'Amarillo' },
      { item_id: 'red', item_text: 'Rojo' }
    ];
    this.gasprecioService.getReasons().subscribe((data: any) => {
      const reasons: any = data;
      reasons.forEach((element: any, index) => {
        const pos = index + 1;
        items.push({ item_id: element._id, item_text: pos + '.- ' + element.heading });
      });

      this.dropdownList = items;
      this.selectedItems = [{ item_id: 'regular', item_text: 'Regular' }];
    });
  }

  changeItems() {
    this.url = '';
    this.selectedItems.map((item) => {
      if (item.item_id === 'regular' || item.item_id === 'premium' || item.item_id === 'diesel') {
        this.url = this.url + 'products[]=' + item.item_id + '&';
      } else if (item.item_id === 'green' || item.item_id === 'yellow' || item.item_id === 'red') {
        this.url = this.url + 'flags[]=' + item.item_id + '&';
      } else if (item.item_text === 'Chihuahua' || item.item_text === 'Obregón' || item.item_text === 'Culiacán' || item.item_text === 'Hermosillo' || item.item_text === 'Mexicalli' || item.item_text === 'Tepic' || item.item_text === 'Guamúchil' || item.item_text === 'Tijuana' ) {
        this.url = this.url + 'zones[]=' + item.item_id + '&';
      } else {
        this.url = this.url + 'causes[]=' + item.item_id + '&';
      }
    });

    if (this.selectedItems.length === 0) {
      this.url = 'products[]=regular&products[]=premium&products[]=diesel&';
      this.selectedItems = [];
    }

    this.sharedService.nextComplement = this.url;
  }

  allItems(items) {
    this.url = '';
    items.map((item) => {
      if (item.item_id === 'regular' || item.item_id === 'premium' || item.item_id === 'diesel') {
        this.url = this.url + 'products[]=' + item.item_id + '&';
      } else if (item.item_id === 'green' || item.item_id === 'yellow' || item.item_id === 'red') {
        this.url = this.url + 'flags[]=' + item.item_id + '&';
      } else if (item.item_text === 'Chihuahua' || item.item_text === 'Obregón' || item.item_text === 'Culiacán' || item.item_text === 'Hermosillo' || item.item_text === 'Mexicalli' || item.item_text === 'Tepic' || item.item_text === 'Guamúchil' || item.item_text === 'Tijuana') {
        this.url = this.url + 'zones[]=' + item.item_id + '&';
      } else {
        this.url = this.url + 'causes[]=' + item.item_id + '&';
      }
    });

    this.sharedService.nextComplement = this.url;
  }

  anyItems() {
    this.selectedItems = [];
    this.url = 'products[]=regular&products[]=premium&products[]=diesel&';
    this.sharedService.nextComplement = this.url;
  }

}
