<div [ngClass]="{'graphic-left': period === 'week' || period === 'month', 'graphic-right': period === 'bi-week' || period === 'day'}">
    <div class="header-c">
        Volumen de Venta - {{title}}
        <epy-tooltip placement="top" style="width: 18px;display: inline-block;">
            <span class="difference" slot="trigger">
                <i class="fa fa-info-circle info" aria-hidden="true"></i>
            </span>
            Promedio de litros vendidos por estación.
        </epy-tooltip>
    </div>
</div>
<div *ngIf="reqst===1" class="text-center loader-container">
    <div class="spinner-grow align-middle text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>

<div *ngIf="reqst===2">
    <div
        [ngClass]="{'graphic-left': period === 'week' || period === 'month', 'graphic-right': period === 'bi-week' || period === 'day'}">
            <highcharts-chart id="mychart3" [Highcharts]="Highcharts" [options]="chartOptions"
             style="width: 100%;height:310px;display: block;"></highcharts-chart> 
    </div>
</div>