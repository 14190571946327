import { Component, OnInit, Input, Output, EventEmitter, } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/services/auth.service';
import { StationService } from 'src/app/modules/station/services/station.service';
import { ClickService } from 'src/app/services/click.service';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import { Observable } from 'rxjs';
declare let $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() pageSelection: string;
  @Output() toggle: EventEmitter<null> = new EventEmitter();
  alerts: any;
  amount = '950';
  arrayBuffer: any;
  brands = [];
  clienteActual: any;
  clientsList: any = [];
  creIdStation: any;
  email: any;
  file: File;
  filterText: any;
  id: any;
  idGroup: any;
  idStation: any;
  isMobile = false;
  letters: any;
  name: any;
  newForm: UntypedFormGroup;
  notifications = false;
  page$!: Observable<string>;
  page: string; // Current page selection
  plan1 = true;
  plan2: any;
  plan3: any;
  plan4: any;
  price = 950;
  profile: any;
  rol: any;
  route: any;
  showCharges = false;
  showDrop = false;
  showMenu = true;
  showEnerser = false;
  showSuscripcion = false;
  station: any;
  stationForm: UntypedFormGroup;
  stationList: any = [];
  submitted = false;
  tax = '152';
  totalAmount = '1,102';
  waitList = false;
  stores = [
    { name: 'Oxxo' },
    { name: '7-Eleven' },
    { name: 'Extra' },
    { name: 'Circle K' },
    { name: 'Super city' },
    { name: 'Otro' }
  ];

  constructor(
    private gasprecioService: GasprecioService,
    public stationService: StationService,
    private clickService: ClickService,
    private formBuilder: UntypedFormBuilder,
    private authenticationService: AuthService,
    private router: Router,
    private sharedService: SharedService
  ) {
    this.page$ = sharedService.sharedPage;
    const details = navigator.userAgent;
    const regexp = /android|iphone|kindle|ipad/i;
    const isMobileDevice = regexp.test(details);
    this.isMobile = isMobileDevice ? true : false;
    this.rol = localStorage.getItem('rol');
    this.idStation = localStorage.getItem('idStation');
    this.id = localStorage.getItem('id') ? localStorage.getItem('id') : '';
    this.idGroup = (this.rol === 'Dios') ? localStorage.getItem('group') || '60afa99655f4ee369d1df409' : localStorage.getItem('group');
    this.showEnerser = this.idGroup === '643f507358715227618de808' || this.idGroup === '649216f6edc3bad5497001f9' ? true : false;
    this.gasprecioService.getBrands(this.idGroup).subscribe((data: any) => {
      this.brands = data.map((element: any) => {
        element['name'] = this.gasprecioService.capitalizeFirstLetter(element['name']);
        return { name: element['name'], value: element['id'] };
      });
    });
  }

  async ngOnInit() {
    this.sharedService.sharedPage.subscribe(val => this.page = val);
    this.stationForm = this.formBuilder.group({
      name: ['', Validators.required],
      cre: ['', Validators.required],
      location: ['', Validators.required],
      website: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', Validators.required],
      brand: ['', Validators.required],
      num_dispensaries: ['', Validators.required],
      inauguration: ['', Validators.required],
      type_conv_store: ['', Validators.required]
    });
    this.newForm = this.formBuilder.group({
      file: ['', Validators.required],
    });
    this.getProfile();
    this.getStation();
    this.getAlerts();
    this.creIdStation = localStorage.getItem('creId') ? localStorage.getItem('creId') : this.authenticationService.creId;
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.route = /[^/]*$/.exec(event.url)[0];
      }
    });
  }

  getAlerts() {
    this.gasprecioService.getAlerts().subscribe((data: any) => {
      this.alerts = data;
      this.alerts.forEach((element: any, index) => {
        if (!element.click) {
          this.notifications = true;
        }
      });
    });
  }

  get f() { return this.stationForm.controls; }

  close(page: string) {
    $('#exampleModalCenter').modal('hide');

    setTimeout(() => {
      this.router.navigate([`/estacion/${page}`]);
    }, 400);
  }

  openSideBar() {
    this.toggle.emit();
  }

  show() {
    this.showDrop = !this.showDrop;
  }

  changeView(page: string) {
    if (page === 'menu') {
      this.showMenu = false;
      this.showSuscripcion = false;
    } else if (page === 'suscripcion') {
      this.showMenu = false;
      this.showSuscripcion = true;
    }
  }

  selectPlan(plan: number) {
    switch (plan) {
      case 1:
        this.plan1 = true;
        this.plan2 = false;
        this.plan3 = false;
        this.plan4 = false;
        this.amount = '950';
        this.price = 950;
        this.tax = '152';
        this.totalAmount = '1,102';
        break;
      case 2:
        this.plan1 = false;
        this.plan2 = true;
        this.plan3 = false;
        this.plan4 = false;
        this.price = 2500;
        this.amount = '2,500';
        this.tax = '400';
        this.totalAmount = '2,900';
        break;
      case 3:
        this.plan1 = false;
        this.plan2 = false;
        this.plan3 = true;
        this.plan4 = false;
        this.price = 2950;
        this.amount = '2,950';
        this.tax = '472';
        this.totalAmount = '3,422';
        break;
      case 4:
        this.plan1 = false;
        this.plan2 = false;
        this.plan3 = false;
        this.plan4 = true;
        this.price = 7500;
        this.amount = '7,500';
        this.tax = '1,200';
        this.totalAmount = '8,700';
        break;
      default:
        break;
    }
  }

  modalBuy() {
    Swal.fire({
      html: `<h2 style="color:#00e07b">Datos de pago</h2><p style="text-align: left">Medios de Pago: <strong>Transferencia o Depósito</strong> <br> Cuenta CLABE: <strong>012180001140999153</strong> <br> A nombre de: <strong>GASPREMIA S.A.DE C.V.</strong> <br> Referencia: <strong><i>	Escribe el nombre de tu Estación</i>	</strong> <br><br> Monto: <strong>$${this.amount} mxn.</strong>  <br> IVA: <strong>$${this.tax} mxn.</strong> <br> Monto Total: <strong>$${this.totalAmount}  mxn.</strong> <br><br> Enviar comprobante de pago a <a href="mailto:hola@gaspre.com"> hola@gaspre.com </a></p>`,
      confirmButtonColor: '#00e07b',
      confirmButtonText: 'Aceptar',
      showCloseButton: true
    }).then((result) => {
      if (result.value) {
        this.sendEmail();
      }
    });
  }

  sendEmail() {
    this.clickService.sendEmail(this.id, this.creIdStation, this.price)
      .pipe()
      .subscribe(
        data => {
          console.log(data);
        }
      );
  }

  changeCharges() {
    this.showCharges = !this.showCharges;
    this.showMenu = !this.showMenu;
  }

  resetView() {
    this.showMenu = true;
    this.showCharges = false;
  }

  changePage(page: string) {
    this.page = page;
    this.router.navigate(['/' + page.toLowerCase()]);
  }

  goTo(type: string, alert: any) {
    localStorage.setItem('creId', alert.cre);
    localStorage.setItem('idStation', alert.idStation);
    this.alertRead(alert._id);
    switch (type) {
      case 'price_rules':
        this.router.navigate(['/gasprecio/reglas-precio']).then(() => {
          window.location.reload();
        });
        break;
      case 'price_changes':
        this.router.navigate(['/gasprecio/monitor']).then(() => {
          window.location.reload();
        });
        break;
      default:
        this.router.navigate(['/gasprecio/monitor']).then(() => {
          window.location.reload();
        });
        break;
    }
  }

  alertRead(idAlert: string, status?: string) {
    if (!status) {
      this.gasprecioService.alertRead(idAlert).subscribe(
        () => {
          this.notifications = false;
          this.getAlerts();
        });
    }
  }

  changeClient(cliente: any) {
    this.clienteActual = cliente.alias;
    localStorage.setItem('creId', cliente.cre);
    localStorage.setItem('idStation', cliente._id);
    window.location.reload();
  }

  changeClientG(cliente: any) {
    localStorage.setItem('creId', cliente.cre);
    localStorage.setItem('idStation', cliente._id);
    this.clienteActual = cliente.alias;
    this.route = 'monitor';
    this.router.navigate(['/gasprecio/monitor']).then(() => {
      window.location.reload();
    });
  }

  async getProfile() {
    const ready = await this.stationService.getProfile().subscribe(
      data => {
        localStorage.setItem('rol', data.rol);
        this.rol = data.rol;
        data.stations.forEach((element, index) => {
          if (this.rol === 'Gaspre') {
            element['alias'] = 'Tu estación ' + (index + 1)
          }
        });
        this.clientsList = data.stations;
        this.stationList = data.stations;
        if (this.clientsList.find((c: any) => c._id === this.idStation)) {
          this.clienteActual = this.clientsList.find((c: any) => c._id === this.idStation).alias;
        } else {
          this.clienteActual = this.clientsList[0].alias;
          localStorage.setItem('creId', this.clientsList[0].cre);
          localStorage.setItem('idStation', this.clientsList[0]._id);
          window.location.reload();
        }
        this.email = data.email;
        this.name = data.name;
        localStorage.setItem('id', data._id);
        this.id = data._id;
        this.idStation = data.idStation;
        this.gasprecioService.setPlayerId(this.id);
        this.letters = this.name.concat(' ').replace(/([a-zA-Z]{0,} )/g, function (match: any) { return (match.trim()[0]); }).slice(0, 2);
      }
    );

    if (ready) {
      this.getStation();
    }
  }

  getStation() {
    this.stationService.getStationProfile().subscribe(
      data => {
        this.station = data.station;
        this.idGroup = this.station.group;
        if (this.idGroup?.length > 0) {
          localStorage.setItem('group', this.station.group);
          this.sharedService.nextGroup = this.station.group;
        }
        if (this.station['custom']['schedule_notifications']) {
          localStorage.setItem('from', this.station['custom']['schedule_notifications']['from']);
          localStorage.setItem('until', this.station['custom']['schedule_notifications']['until']);
        }

        if (this.station['custom']['marketer']) {
          localStorage.setItem('marketer', this.station['custom']['marketer']);
        }

        if (this.station.name) {
          this.stationForm.patchValue({
            name: this.station.name,
          });
        }

        if (this.station.waiting_list) {
          this.waitList = this.station.waiting_list;
        }

        if (this.station.cre) {
          this.stationForm.patchValue({
            cre: this.station.cre,
          });
        }

        if (this.station.location) {
          this.stationForm.patchValue({
            location: this.station.location,
          });
        }

        if (this.station.website) {
          this.stationForm.patchValue({
            website: this.station.website,
          });
        }

        if (this.station.phone) {
          this.stationForm.patchValue({
            phone: this.station.phone,
          });
        }

        if (this.station.email) {
          this.stationForm.patchValue({
            email: this.station.email,
          });
        }

        if (this.station.brand) {
          this.stationForm.patchValue({
            brand: this.station.brand,
          });
        }

        if (this.station.num_dispensaries) {
          this.stationForm.patchValue({
            num_dispensaries: this.station.num_dispensaries,
          });
        }

        if (this.station.inauguration) {
          this.stationForm.patchValue({
            inauguration: this.station.inauguration,
          });
        }

        if (this.station.type_conv_store) {
          this.stationForm.patchValue({
            type_conv_store: this.station.type_conv_store,
          });
        }
      },
      error => {
        console.log(error);
      });
  }

  editStation() {

    this.submitted = true;

    // stop here if form is invalid
    if (this.stationForm.invalid) {
      return;
    }

    this.stationService.updateStationProfile(this.f.name.value, this.f.location.value, this.f.website.value, this.f.email.value, this.f.phone.value, this.f.type_conv_store.value, this.f.inauguration.value, this.f.num_dispensaries.value, this.f.brand.value)
      .pipe()
      .subscribe({
        next: () => {
          this.gasprecioService.displayMessage('success', 'Información actualizada!', 'La estación ha sido actualizado con éxito.')
        },
        error: () => {
          this.gasprecioService.displayMessage('error', 'Error', 'La estación no ha sido actualizada con éxito.')
        }
      });
  }

  get n() { return this.newForm.controls; }

  loadFile(event: any) {
    this.file = event.target.files[0];
  }

  uploadFile() {
    // stop here if form is invalid
    if (this.newForm.invalid) {
      return;
    }

    this.gasprecioService.showLoader('Subiendo información', 'Por favor, aguarda un momento...');
    this.stationService.uploadChargeRecord(this.file)
      .subscribe({
        next: (data) => {
          Swal.hideLoading();
          if (data.err.message === 'Record already exists') {
            const charges = data.err.total_charges;
            this.confirmOverwrite(this.file, charges);
          } else {
            this.gasprecioService.displayMessage('success', '¡El archivo se ha subido con éxito!')
          }
        },
        error: () => {
          this.gasprecioService.displayMessage('error', 'Error', 'Hubo un error al cargar el archivo')
        }
      });
  }

  confirmOverwrite(file: any, charges: any) {
    Swal.fire({
      title: '¿Estás seguro que deseas sobrescribir las cargas?',
      text: `Ya existen ${charges} cargas`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#00e07b',
      cancelButtonColor: '#fa1975',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
      confirmButtonText: 'Si, sobrescribir'
    }).then((result) => {
      if (result.value) {
        this.stationService.overwriteChargeRecord(file)
          .subscribe({
            next: () => {
              this.gasprecioService.displayMessage('success', '¡El archivo se ha sobrescrito con éxito!')
            },
            error: () => {
              this.gasprecioService.displayMessage('error', 'Error', 'Hubo un error al sobrescribir el archivo')
            }
          });
      }
    });
  }

  clickPage(page: string) {
    this.clickService.clickPage(this.id, this.idStation, page, this.page)
      .pipe()
      .subscribe(
        data => {
          console.log(data)
        });
  }

  resetMarketIntelligence() {
    this.sharedService.resetMarketIntelligence();
  }

  modalWaitList() {
    Swal.fire({
      html: `<p style="font-weight:bold"> ¿Quieres ser de los primeros en utilizar esta gran herramienta? </p> <p> Registrate y se de los primeros usuarios en probarlo  </p>`,
      footer: '<p>Si deseas obtener más información da click <a style="color:#00e07b" href="https://www.gaspremia.com" target="_blank"> aquí </a></p>',
      icon: 'warning',
      reverseButtons: true,
      confirmButtonColor: '#00e07b',
      confirmButtonText: 'Registrarme',
      showCancelButton: true,
      cancelButtonColor: '#b82f26',
      cancelButtonText: 'Después',
      showCloseButton: true
    }).then((result) => {
      if (result.value) {
        this.addWaitList();
      }
    });
  }

  addWaitList() {
    this.clickService.addWaitList(this.id, this.idStation)
      .pipe()
      .subscribe(
        () => {
          Swal.fire({
            title: 'Has sido añadido a la lista de espera de GasPremia',
            footer: '<p>Si deseas obtener más información da click <a style="color:#00e07b" href="https://www.gaspremia.com" target="_blank"> aquí </a></p>',
            icon: 'success',
            confirmButtonColor: '#00e07b',
            confirmButtonText: 'Aceptar',
          });
        });
  }

  async logout() {
    this.authenticationService.logout();
  }

  capitalizeFirstLetter(text: string) {
    return text
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  filterItem(str: string) {
    if (typeof str === 'string') {
      this.clientsList = this.stationList.filter((d: any) => {
        if (d['alias'].toLowerCase().indexOf(str.toLowerCase()) !== -1) {
          return true;
        }
        return false;
      });
    }
  }

  clickIcon(type) {
    let page = this.page;
    if(page === 'General') {
      page = 'Tablero'
    } else if (page === 'Mercado') {
      page = 'Modulo Inteligencia'
    }

    if(type !== page) {
      this.resetMarketIntelligence();
      this.clickPage(type)
    }

  }
}
