import { Component, Input, OnDestroy } from '@angular/core';
import { combineLatest, Subject, Subscription, takeUntil } from 'rxjs';
import { GasprecioService } from '../../../gasprecio/services/gasprecio.service';
import { SharedService } from '../../../gasprecio/services/shared.service';
import { Router, Event as NavigationEvent, NavigationEnd } from '@angular/router';
import Highcharts from 'highcharts';
import { GroupIds } from 'src/app/modules/gasprecio/enums/groups-ids.enum';
import moment from 'moment';

@Component({
  selector: 'app-chart-sales-distribution',
  templateUrl: './chart-sales-distribution.component.html',
  styleUrls: ['./chart-sales-distribution.component.scss']
})
export class ChartSalesDistributionComponent implements OnDestroy {

  @Input() variable = 'liters';
  @Input() type = 'Autos';
  @Input() station: any = false;
  chartOptions: Highcharts.Options;
  data = [];
  date = moment('2024-09-01');
  event$!: Subscription;
  Highcharts = Highcharts;
  idGroup: GroupIds | string = GroupIds.Pa;
  loading = false;
  private destroy$ = new Subject<void>();
  productType = 'regular';
  reqst = 0;
  rol;
  route;
  stationId: any;
  period;
  zone;
  changeZone = false;

  constructor(
    private gasprecioService: GasprecioService,
    private sharedService: SharedService,
    private router: Router
  ) {
    this.rol = localStorage.getItem('rol');
    this.idGroup = (this.rol === 'Dios') ? localStorage.getItem('group') || GroupIds.Pa : localStorage.getItem('group');
    this.stationId = localStorage.getItem('idStation');
    this.event$ = this.router.events.subscribe((event: NavigationEvent) => {
      if (event instanceof NavigationEnd) {
        this.route = /[^/]*$/.exec(event.url)[0];
      }
    });

    combineLatest([
      this.sharedService.sharedGroup,
      this.sharedService.sharedZone,
      this.sharedService.sharedStationSelected,
      this.sharedService.sharedSelectedMonth,
      this.sharedService.sharedProductTypeTotal,
    ]).pipe(takeUntil(this.destroy$)).subscribe(([idGroup, zone, stationId, period, productType]) => {
      if (idGroup !== this.idGroup) {
        this.idGroup = idGroup;
      }

      if (this.zone !== zone) {
        if(this.zone !== undefined) {
          this.changeZone = true;
        }
        this.zone = zone;
      }

      if (this.stationId !== stationId) {
        this.stationId = stationId;
      }

      if (period !== this.date) {
        this.date = period;
      }

      if (this.productType !== productType) {
        this.productType = productType;
      }

      if (!this.loading) {
        this.getHeatMapDistribution();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getHeatMapDistribution() {
    this.loading = true;
    this.reqst = 0;
    this.data = [];
    setTimeout(() => {
      const url = this.station ? this.gasprecioService.getHeatMapDistributionByStation(this.idGroup, this.variable, this.date ) : 
      this.stationId === 'zone' ?  this.gasprecioService.getHeatMapDistribution(this.idGroup, this.zone, this.variable, this.date, true) : 
      this.gasprecioService.getHeatMapDistribution(this.idGroup, this.stationId, this.variable, this.date); 
      url.subscribe({
        next: (data: any) => {
          const info = data.reverse();
          info.map((x: any) => {
            this.data.push({ 
              name: `${this.gasprecioService.capitalizeFirstLetter(x['product'] || '')} - ${this.gasprecioService.numberWithCommas((x['value']?.toFixed(0) || '0'))} lts.`,
              y: +(x['pct']?.toFixed(1) || 0),
              color: x['product'] === 'regular' ? '#159546' : 
                     x['product'] === 'premium' ? '#ea0000' : 
                     x['product'] === 'diesel' ? '#1e2228' : '#cccccc'  // Color de respaldo
            });          
          });   
          this.setData();
        }, complete: () => {
          this.reqst = 1;
        }, error: () => {
          this.loading = false;
          this.reqst = 2;
        }
      });
    }, 3000);
  }

  setData() {
    this.chartOptions = {
      credits: {
        enabled: false
      },
      chart: {
        type: 'pie',
        plotShadow: false
      },
      title: {
        text: `Distribución de Venta`,
        style: {
          fontSize: '0'
        }
      },
      exporting: {
        filename: `Distribución de Venta`,
        sourceWidth: 1200,
        sourceHeight: 800,
      },
      navigation: {
        buttonOptions: {
          align: 'right',
          y: -10
        }
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      legend: {
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'top',
        itemDistance: 10,
        y: 1,
        padding: 1,
        itemMarginTop: 1,
        itemMarginBottom: 1,
        itemHoverStyle: {
          color: '#00E07B',
        }
      },
      xAxis: {
        categories: [],
        gridLineWidth: 1,
        gridLineDashStyle: 'LongDash',
        title: {
          text: 'Fecha',
          style: {
            fontSize: '0'
          }
        },
        labels: {
          style: {
            fontSize: '11px',
          },
          y: 14
        }
      },
      yAxis: [
        {
          title: {
            text: 'Productos',
            style: {
              fontSize: '0'
            }
          },
          labels: {
            format: '{value:,.0f} lts',
            style: {
              fontSize: '11px'
            }
          },
          tickAmount: 10,
          gridLineDashStyle: 'LongDash'
        }
      ],
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %',
            distance: 10
          },
          showInLegend: true
        }
      },
      series: [{
        type: 'pie',
        name: 'Ventas',
        data: this.data
      }]
    };

    setTimeout(() => {
      this.loading = false;
    }, 100);
  }
}
