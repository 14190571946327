import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-group',
  templateUrl: './select-group.component.html',
  styleUrls: ['./select-group.component.scss']
})
export class SelectGroupComponent implements OnInit, OnDestroy {

  public items: any[] = [];

  public idGroup: any = [];
  public ngxDisabled = true;
  idGroup$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private gasprecioService: GasprecioService,
    private sharedService: SharedService
    ) {
    this.idGroup$ = sharedService.sharedGroup.subscribe((idGroup: string) => {
      if (idGroup !== this.idGroup) {
        this.idGroup = idGroup;
      }
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnInit(): void {
    this.getGroups();
  }

  ngOnDestroy() {
    this.idGroup$.unsubscribe();
  }

  getGroups() {
    this.gasprecioService.getGroups().subscribe((data: any) => {
      data.map((group) => {
        this.items.push({ name: group['name'], value: group['_id']});
      });
      this.ngxDisabled = false;
    });
  }

  changeGroup(event: any) {
    this.idGroup = event;
    this.sharedService.nextGroup = this.idGroup;
    this.sharedService.nextZone = '';
  }
}
