import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import { SelectItem } from '../../models/select-item.model';

@Component({
  selector: 'app-select-percentage',
  templateUrl: './select-percentage.component.html',
  styleUrls: ['./select-percentage.component.scss']
})
export class SelectPercentageComponent implements OnInit, OnDestroy {
  @Input() percentages!: number[];
  percentage$: Subscription;
  percentage!: string;
  items: SelectItem[] = [];

  constructor(
    private readonly sharedService: SharedService
  ) {
    this.percentage$ = this.sharedService.sharedSelectedPercentage.subscribe((p) => {
      this.percentage = p;
    });
  }

  ngOnInit(): void {
    this.createItems();
    this.changePercentage(this.items[1].value);
  }

  ngOnDestroy(): void {
    this.percentage$.unsubscribe();
  }

  createItems(): void {
    for (const p of this.percentages) {
      this.items.push({ name: `${p}%`, value: p.toString() })
    }
  }

  changePercentage(percentage: string): void {
    this.sharedService.nextSelectedPercentage = percentage;
  }
}
