import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-multiselect-zone',
  templateUrl: './multiselect-zone.component.html',
  styleUrls: ['./multiselect-zone.component.scss']
})
export class MultiselectZoneComponent implements OnInit, OnDestroy {

  selectedComplementZones = null;
  selectedComplementZones$!: Subscription;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Todas',
    unSelectAllText: 'Todas',
    itemsShowLimit: 1,
    allowSearchFilter: true,
    clearSearchFilter: true,
    searchPlaceholderText: 'Buscar...',
    maxHeight: 140
  };
  url: string;
  public idGroup: any;
  rol;
  idGroup$: Subscription;

  constructor(
    private sharedService: SharedService,
    private gasprecioService: GasprecioService
  ) {
    this.rol = localStorage.getItem('rol');
    this.idGroup = (this.rol === 'Dios') ? localStorage.getItem('group') || '60afa99655f4ee369d1df409' : localStorage.getItem('group');
    this.idGroup$ = sharedService.sharedGroup.subscribe((idGroup: string) => {
      if (idGroup !== this.idGroup) {
        this.idGroup = idGroup;
        this.getZones();
      }
    });

    this.selectedComplementZones$ = this.sharedService.sharedSelectedComplementZones.subscribe(selectedComplementZones => {
      if (selectedComplementZones !== this.selectedComplementZones) {
        this.selectedItems = [...selectedComplementZones];
      }
    });
  }

  ngOnInit(): void {
    this.getZones();
  }

  ngOnDestroy() {
    this.idGroup$.unsubscribe();
    this.selectedComplementZones$.unsubscribe(); 
  }

  getZones() {
    const items = [];
    this.gasprecioService.getZones(this.idGroup).subscribe((data: any) => {
      const zones: any = data;
      zones.forEach((element: any) => {
        if (element.zone_id !== '6178671b46818a57510e921b' && element.zone_id !== '6178671b46818a57510e921a') {
          items.push({ item_id: element['zone_id'], item_text: this.gasprecioService.capitalizeFirstLetter(element['name']) });
        }
      });

      this.dropdownList = items;
    });
  }

  changeItems() {
    this.url = '';
    this.selectedItems.map((item) => {
      this.url = this.url + 'zones[]=' + item.item_id + '&';
    });

    this.sharedService.nextComplementZones = this.url;
    this.sharedService.nextSelectedComplementZones = this.selectedItems;
  }

  allItems(items) {
    this.url = '';
    items.map((item) => {
      this.url = this.url + 'zones[]=' + item.item_id + '&';
    });

    this.sharedService.nextComplementZones = this.url;
    this.sharedService.nextSelectedComplementZones = this.selectedItems;
  }

  anyItems() {
    this.selectedItems = [];
    this.url = '';
    this.sharedService.nextComplementZones = this.url;
    this.sharedService.nextSelectedComplementZones = this.selectedItems;
  }

}
