<div class="header-c" style="margin-bottom: 10px;margin-top: 10px;">
    <div class="select-type">
        <app-select-variable-px-opt></app-select-variable-px-opt>
    </div> - Distancia o diferencia VS <span style="text-decoration: underline;text-underline-offset: 4px;">precio promedio</span> 
    <epy-tooltip placement="top" style="width: 18px;display: inline-block;text-align: left;">
        <span class="difference" slot="trigger">
            <i class="fa fa-info-circle info" aria-hidden="true"></i>
        </span>
        Diferencia de tu precio vs el precio promedio, a que distancia te ubicas.<br>
        Negativo: Dif Px Prom < 0 <br>
        Nula: Dif Px Prom = 0 <br>
        Poca: 0 < Dif Px Prom <= 0.50<br>
        Media: 0.50 < Dif Px Prom <= 1.00<br>
        Mucha: Dif Px Prom > 1.00<br>
    </epy-tooltip>
</div>

<div id="container-max-highcharts-dif" style="width: 100%;" *ngIf="reqst === 1">
    <highcharts-chart [Highcharts]="Highcharts" [options]="chartOptions"></highcharts-chart>
</div>

<div *ngIf="reqst === 0" class="text-center loader-container">
    <div class="spinner-grow align-middle text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>