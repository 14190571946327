<div class="header-c">
    Volumen de Transacciones (# Cargas)
    <epy-tooltip placement="top" style="width: 18px;display: inline-block;">
        <span class="difference" slot="trigger">
            <i class="fa fa-info-circle info" aria-hidden="true"></i>
        </span>
        Volumen de Transacciones (# Cargas) por estación.
    </epy-tooltip>
    &nbsp;
    <input type="checkbox" id="checkbox-transaction" [(ngModel)]="showScale" (ngModelChange)="setData()" *ngIf="productType !== 'total'"> 
    <label style="font-size: 13px;margin-bottom: 0;margin-left: .2%;" for="checkbox-transaction" *ngIf="productType !== 'total'">
        Escala desde 0
    </label>
</div>
<div *ngIf="reqst===1" class="text-center loader-container">
    <div class="spinner-grow align-middle text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>

<div [ngClass]="{'flex-item': (period === 'month' || (period === 'day' && (days === 15 || days === 30))) && !station,'flex-right': period === 'week' || (period === 'day' && days !== 15 && days !== 30) || station }"
    *ngIf="reqst===2">
    <div style="margin-right: 1%;">
        <highcharts-chart id="mychart7" [Highcharts]="Highcharts" [options]="chartOptions" [ngStyle]="{ 'height': station || period !== 'month' && !(period === 'week' && (weeks === 8 || weeks === 12)) && !(period === 'day' && (days === 15 || days === 30)) ? 'auto': '310px'}" style="width: 100%;display: block;"></highcharts-chart>
    </div>
</div>
