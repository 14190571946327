import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { GasprecioService } from '../../../gasprecio/services/gasprecio.service';
import { SharedService } from '../../../gasprecio/services/shared.service';
import { Router, Event as NavigationEvent, NavigationEnd } from '@angular/router';
import Highcharts from 'highcharts';

@Component({
  selector: 'app-chart-tar-buy',
  templateUrl: './chart-tar-buy.component.html',
  styleUrls: ['./chart-tar-buy.component.scss']
})
export class ChartTarBuyComponent implements OnInit, OnDestroy {

  @Input() station: any = false;
  date: any;
  days = 30;
  days$!: Subscription;
  event$!: Subscription;
  Highcharts: typeof Highcharts = Highcharts;
  idGroup = '60afa99655f4ee369d1df409';
  idGroup$!: Subscription;
  imgData2;
  importants;
  importants$: Subscription;
  isAnimationEnabled = false;
  months = 12;
  months$!: Subscription;
  period = 'day';
  period$!: Subscription;
  productType = 'regular';
  productType$!: Subscription;
  purchasePrice;
  reqst = 0;
  rol;
  route;
  selectedCluster;
  selectedCluster$!: Subscription;
  selectedCluster2;
  selectedCluster2$!: Subscription;
  selectedCluster3;
  selectedCluster3$!: Subscription;
  selectedCluster4;
  selectedCluster4$!: Subscription;
  showEnerser = false;
  showYAxis = false;
  tarPrice: any;
  weeks = 12;
  weeks$!: Subscription;
  zoneSelected = null;
  zoneSelected$: Subscription;
  chartOptions: Highcharts.Options = {
    credits: {
      enabled: false
    },
    chart: {
      type: 'line'
    },
    title: {
      text: `Precio TAR - Precio Compra`,
      style: {
        fontSize: '0'
      }
    },
    xAxis: {
      categories: [],
      gridLineWidth: 1,
      gridLineDashStyle: 'LongDash',
      title: {
        text: 'Fecha',
        style: {
          fontSize: '0'
        }
      },
      labels: {
        style: {
          fontSize: '11px',
        },
        rotation: -60,
        y: 14
      }
    },
    yAxis: [
      {
        title: {
          text: 'Precio TAR',
          style: {
            fontSize: '0'
          }
        },
        labels: {
          format: '$ {value:.2f}',
          style: {
            fontSize: '11px'
          }
        },
        tickAmount: 10,
        gridLineDashStyle: 'LongDash'
      },
      {
        title: {
          text: 'Precio Compra',
          style: {
            fontSize: '0'
          }
        },
        labels: {
          format: '$ {value:.2f}',
          style: {
            fontSize: '11px'
          }
        },
        opposite: true,
        tickAmount: 10,
        gridLineDashStyle: 'LongDash'
      }
    ],
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'top',
      itemDistance: 10,
      y: 1,
      padding: 1,
      itemMarginTop: 1,
      itemMarginBottom: 1,
      itemHoverStyle: {
        color: '#00E07B',
      }
    },
    tooltip: {
      split: true,
      pointFormat: '{series.name}: <b>$ {point.y:.2f}</b>'
    },
    plotOptions: {
      series: {
        animation: {
          duration: 1000
        },
        marker: {
          radius: 2
        },
        lineWidth: 1
      }
    },
    exporting: {
      filename: `Precio TAR - Precio Compra`,
      sourceWidth: 1200,
      sourceHeight: 800,
    },
    navigation: {
      buttonOptions: {
        align: 'right',
        y: -10
      }
    },
    series: []
  };

  constructor(
    private gasprecioService: GasprecioService,
    private sharedService: SharedService,
    private router: Router
  ) {
    this.event$ = this.router.events.subscribe((event: NavigationEvent) => {
      if (event instanceof NavigationEnd) {
        this.route = /[^/]*$/.exec(event.url)[0];
      }
    });

    this.productType$ = this.sharedService.sharedProductTypeTotal.subscribe(productType => {
      if (this.productType !== productType) {
        this.productType = productType;
        if (this.reqst > 1) {
          this.setData();
        }
      }
    });

    this.idGroup = (this.rol === 'Dios') ? localStorage.getItem('group') || '60afa99655f4ee369d1df409' : localStorage.getItem('group');
    this.showEnerser = this.idGroup === '643f507358715227618de808' || this.idGroup === '649216f6edc3bad5497001f9' ? true : false;
    this.isAnimationEnabled = this.showEnerser ? true : false;
    this.idGroup$ = sharedService.sharedGroup.subscribe((idGroup: string) => {
      if (idGroup !== this.idGroup) {
        this.idGroup = idGroup;
        if (this.reqst > 1) {
          this.zoneSelected = '';
          this.getDashboardTarPricePurchasePrice();
        }
      }
    });

    this.zoneSelected$ = sharedService.sharedZoneDashboard.subscribe((zone: string) => {
      if (zone === 'Total') {
        this.zoneSelected = null;
        if (zone !== this.zoneSelected) {
          this.getDashboardTarPricePurchasePrice();
        }
      } else {
        if (zone !== this.zoneSelected) {
          this.zoneSelected = zone;
          this.getDashboardTarPricePurchasePrice();
        }
      }
    });

    this.period$ = this.sharedService.sharedPeriodDashboard.subscribe(period => {
      if (period !== this.period) {
        this.period = period;
        if (this.reqst > 1) {
          this.getDashboardTarPricePurchasePrice();
        }
      }
    });

    this.days$ = this.sharedService.sharedDaysDashboard.subscribe(days => {
      if (days !== this.days) {
        this.days = days;
        if (this.reqst > 1) {
          this.getDashboardTarPricePurchasePrice();
        }
      }
    });

    this.weeks$ = this.sharedService.sharedWeeksAgo.subscribe(weeks => {
      if (weeks !== this.weeks) {
        this.weeks = weeks;
        if (this.reqst > 1) {
          this.getDashboardTarPricePurchasePrice();
        }
      }
    });

    this.months$ = this.sharedService.sharedMonthsAgo.subscribe(months => {
      if (months !== this.months) {
        this.months = months;
        if (this.reqst > 1) {
          this.getDashboardTarPricePurchasePrice();
        }
      }
    });

    this.importants$ = sharedService.sharedImportants.subscribe((importants: string) => {
      if (this.importants !== importants) {
        this.importants = importants;
        if (this.reqst > 1) {
          this.getDashboardTarPricePurchasePrice();
        }
      }
    });


    this.selectedCluster$ = sharedService.sharedSelectedComplementClusters.subscribe((selectedCluster: any) => {
      if (selectedCluster !== this.selectedCluster) {
        this.selectedCluster = selectedCluster;
        if (this.reqst > 1) {
          this.getDashboardTarPricePurchasePrice();
        }
      }
    });

    this.selectedCluster2$ = sharedService.sharedSelectedComplementClusters2.subscribe((selectedCluster2: any) => {
      if (selectedCluster2 !== this.selectedCluster2) {
        this.selectedCluster2 = selectedCluster2;
        if (this.reqst > 1) {
          this.getDashboardTarPricePurchasePrice();
        }
      }
    });

    this.selectedCluster3$ = sharedService.sharedSelectedComplementClusters3.subscribe((selectedCluster3: any) => {
      if (selectedCluster3 !== this.selectedCluster3) {
        this.selectedCluster3 = selectedCluster3;
        if (this.reqst > 1) {
          this.getDashboardTarPricePurchasePrice();
        }
      }
    });

    this.selectedCluster4$ = sharedService.sharedSelectedComplementClusters4.subscribe((selectedCluster4: any) => {
      if (selectedCluster4 !== this.selectedCluster4) {
        this.selectedCluster4 = selectedCluster4;
        if (this.reqst > 1) {
          this.getDashboardTarPricePurchasePrice();
        }
      }
    });
  }

  ngOnInit() {
    this.getDashboardTarPricePurchasePrice();
  }

  ngOnDestroy(): void {
    this.productType$.unsubscribe();
    this.idGroup$.unsubscribe();
    this.zoneSelected$.unsubscribe();
    this.period$.unsubscribe();
    this.days$.unsubscribe();
    this.weeks$.unsubscribe();
    this.months$.unsubscribe();
    this.importants$.unsubscribe();
    this.event$.unsubscribe();
    this.selectedCluster$.unsubscribe();
    this.selectedCluster2$.unsubscribe();
    this.selectedCluster3$.unsubscribe();
    this.selectedCluster4$.unsubscribe();
  }

  getDashboardTarPricePurchasePrice() {
    this.reqst = 1;
    let typeValue = 'value';
    const periodMap = {
      'day': this.days,
      'week': this.weeks,
      'month': this.months
    };
    const n = periodMap[this.period];
    typeValue = (typeof n === 'number') ? 'value' : 'date';
    const url = this.station ?
      this.gasprecioService.getDashboardTarPricePurchasePriceStation(this.period, typeValue, n) :
      this.gasprecioService.getDashboardTarPricePurchasePrice(this.idGroup, this.zoneSelected, this.period, this.importants, typeValue, n, this.selectedCluster, this.selectedCluster2, this.selectedCluster3, this.selectedCluster4);
    url.subscribe({
      next: (data: any) => {
        const info = data;
        this.tarPrice = info['tar_price'];
        this.purchasePrice = info['purchase_price'];
        this.setData();
      }, complete: () => {
        this.reqst = 2;
      }, error: () => {
        this.reqst = 3;
      }
    });
  }

  setData() {
    this.reqst = 1;
    const colorLight = this.gasprecioService.getColorString(this.gasprecioService.colorsProductsLight[this.gasprecioService.getProductTypeIndex(this.productType)]);
    const tarChartData: any = {
      name: 'Precio TAR',
      data: this.tarPrice.map((v: any) => {
        if (v[this.productType] === null) {
          return null; // Devuelve null si el valor es null
        } else {
          return +v[this.productType]; // Devuelve el valor convertido a número
        }
      }),
      color: colorLight,
      dashStyle: 'ShortDot',
      lineWidth: 2,
      marker: { radius: 2 },
      dataLabels: {
        enabled: this.isAnimationEnabled,
        format: '$ {y:.2f}',
        style: { fontSize: '10' },
      },
      yAxis: 0
    };

    const purchaseChartData: any = {
      name: 'Precio de Compra',
      data: this.purchasePrice.map((v: any) => {
        if (v[this.productType] === null) {
          return null; // Devuelve null si el valor es null
        } else {
          return +v[this.productType]; // Devuelve el valor convertido a número
        }
      }),
      color: colorLight,
      lineWidth: 2,
      dataLabels: {
        enabled: this.isAnimationEnabled,
        format: '$ {y:.2f}',
        style: { fontSize: '10' },
      },
      yAxis: this.showYAxis ? 0 : 1
    };

    const lineXaxisLegendGenerator = {
      day: (v: any) => this.gasprecioService.parseDateDM(v.date),
      week: (v: any) => v.date_alias,
      month: (v: any) => this.gasprecioService.parseDateM(v.date)
    };

    const lineXaxisLegend = this.purchasePrice.map(lineXaxisLegendGenerator[this.period]);
    this.chartOptions['xAxis']['categories'] = lineXaxisLegend;
    this.chartOptions.series = [tarChartData, purchaseChartData];
    setTimeout(() => {
      this.reqst = 2;
    }, 100);
  }

  generateImageChart() {
    const canvas2: any = document.getElementById('mychart2');
    const imgData2 = canvas2.toDataURL('image/png');
    this.sharedService.nextImgData2 = imgData2;
  }
}