<!-- Topbar header - style you can find in pages.scss -->
<!-- ============================================================== -->
<header class="topbar">
    <nav class="navbar top-navbar navbar-expand-md navbar-light">
        <!-- ============================================================== -->
        <!-- Logo -->
        <!-- ============================================================== -->
        <div class="navbar-header">
            <a class="navbar-brand" aria-expanded="false" id="3">
                <div class="header-container">
                    <b>
                        <img src="assets/images/logo.png" alt="homepage" class="logo" />
                        <i class="fa fa-bars menu" (click)="openSideBar()"></i>
                    </b>
                    <a class="nav-link dropdown-toggle waves-effect waves-dark" [routerLink]="'/gasprecio/monitor'"
                        (click)="clickIcon('Monitor');" *ngIf="isMobile">
                        <i class="mdi mdi-gas-station icon-mobile" [ngClass]="{'active': (page$ | async) === 'Monitor' && isMobile}"></i>
                    </a>
                    
                    <a class="nav-link dropdown-toggle waves-effect waves-dark" [routerLink]="'/gasprecio/general'"
                        [queryParams]="{view: 'rdc'}" (click)="clickIcon('Tablero');"
                        *ngIf="idGroup === '615efe30b318ce63b8971986' && isMobile">
                        <i class="mdi mdi-television-guide icon-mobile"  [ngClass]="{'active': (page$ | async) === 'General' && idGroup === '615efe30b318ce63b8971986' && isMobile}"></i>
                    </a>
                    
                    <a class="nav-link dropdown-toggle waves-effect waves-dark" [routerLink]="'/gasprecio/general'"
                        (click)="clickIcon('Tablero');resetMarketIntelligence();"
                        *ngIf="idGroup !== '615efe30b318ce63b8971986' && isMobile">
                        <i class="mdi mdi-television-guide icon-mobile" [ngClass]="{'active': (page$ | async) === 'General' && idGroup !== '615efe30b318ce63b8971986' && isMobile}"></i>
                    </a>
                    
                    <a class="nav-link dropdown-toggle waves-effect waves-dark" [routerLink]="'/gasprecio/inteligencia-mercado'"
                        (click)="clickIcon('Modulo Inteligencia')" *ngIf="isMobile && rol !== 'Stock'">
                        <i class="mdi mdi-google-circles-extended icon-mobile" [ngClass]="{'active': (page$ | async) === 'Mercado' && isMobile}"></i>
                    </a>
                    
                    <epy-tooltip style="padding-top: 8px;" placement="bottom" [routerLink]="'/gasprecio/monitor'"
                        (click)="clickIcon('Monitor');" *ngIf="!isMobile">
                        <span class="difference" slot="trigger">
                            <i class="mdi mdi-gas-station icon-desktop" [ngClass]="{'active': (page$ | async) === 'Monitor' && !isMobile}"></i>
                        </span>
                        Monitor
                    </epy-tooltip>
                    
                    <epy-tooltip style="padding-top: 8px;" placement="bottom" [routerLink]="'/gasprecio/general'"
                        [queryParams]="{view: 'rdc'}" (click)="clickIcon('Tablero');"
                        *ngIf="idGroup === '615efe30b318ce63b8971986' && idGroup !== '643f507358715227618de808' && !isMobile">
                        <span class="difference" slot="trigger">
                            <i class="mdi mdi-television-guide icon-desktop" [ngClass]="{'active': (page$ | async) === 'General' && !isMobile}"></i>
                        </span>
                        Tablero Estaciones
                    </epy-tooltip>

                    <epy-tooltip style="padding-top: 8px;" placement="bottom" [routerLink]="'/gasprecio/general'"
                        [queryParams]="{view: 'national-prices'}" (click)="clickIcon('Tablero');"
                        *ngIf="idGroup !== '615efe30b318ce63b8971986' && idGroup === '643f507358715227618de808' && !isMobile && rol !== 'Lector'">
                        <span class="difference" slot="trigger">
                            <i class="mdi mdi-television-guide icon-desktop" [ngClass]="{'active': (page$ | async) === 'General' && idGroup !== '615efe30b318ce63b8971986' && idGroup === '643f507358715227618de808' && !isMobile }"></i>
                        </span>
                        Tablero Estaciones
                    </epy-tooltip>
                    
                    <epy-tooltip style="padding-top: 8px;" placement="bottom" [routerLink]="'/gasprecio/general'"
                        (click)="clickIcon('Tablero');"
                        *ngIf="idGroup !== '615efe30b318ce63b8971986' && (idGroup !== '643f507358715227618de808' || rol === 'Lector') && !isMobile">
                        <span class="difference" slot="trigger">
                            <i class="mdi mdi-television-guide icon-desktop" [ngClass]="{'active': (page$ | async) === 'General' && idGroup !== '615efe30b318ce63b8971986' && idGroup !== '643f507358715227618de808' && !isMobile}"></i>
                        </span>
                        Tablero Estaciones
                    </epy-tooltip>
                    
                    <epy-tooltip style="padding-top: 8px;" placement="bottom" [routerLink]="'/gasprecio/inteligencia-mercado'"
                        (click)="clickIcon('Modulo Inteligencia');" *ngIf="!isMobile  && rol !== 'Stock'">
                        <span class="difference" slot="trigger">
                            <i class="mdi mdi-google-circles-extended icon-desktop" [ngClass]="{'active': (page$ | async) === 'Mercado' && !isMobile}"></i>
                        </span>
                        Inteligencia de Mercado o Marca
                    </epy-tooltip>
                </div>
            </a>

            <div class="dropdown-menu animated fadeIn custom-dropdown" aria-labelledby="3">
                <ul class="dropdown-user">
                    <li><a [routerLink]="'/gaspremia'" (click)="changePage('GasPremia');clickPage('Metricas')"><i
                                class="fa fa-gift"></i>
                            GasPremia</a></li>
                    <li><a [routerLink]="'/gasprecio'" (click)="changePage('GasPrecio');clickPage('Monitor')"><i
                                class="fa fa-usd"></i>
                            GasPre</a></li>
                </ul>
            </div>
        </div>
        <!-- ============================================================== -->
        <!-- End Logo -->
        <!-- ============================================================== -->
        <div class="navbar-collapse">
            <!-- ============================================================== -->
            <!-- toggle and nav items -->
            <!-- ============================================================== -->
            <ul class="navbar-nav mr-auto">
                <!-- This is  -->

            </ul>
            <!-- ============================================================== -->
            <!-- User profile and search -->
            <!-- ============================================================== -->
            <ul class="navbar-nav my-lg-0">
                <!-- ============================================================== -->
                <!-- Settings -->
                <!-- ============================================================== 
                <li class="nav-item dropdown" *ngIf="rol==='Administrador' || rol === 'Dios'">
                    <a class="nav-link dropdown-toggle waves-effect waves-dark" id="2" data-toggle="modal"
                        data-target="#exampleModalCenter" (click)="clickPage('Configuracion')">
                        <i class="mdi mdi-settings"></i>
                    </a>
                    
                    <div class="modal fade animated slideInRight" id="exampleModalCenter" tabindex="-1" role="dialog"
                        aria-hidden="true">
                        <div class="modal-dialog custom-dialog" role="document">
                            <div class="modal-content all-screen">
                                <div class="modal-header">
                                    <h4 class="modal-title bold" style="color: black;">
                                        <strong>Configuración</strong>
                                    </h4>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                                        (click)="resetView()">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body animated slideInRight" *ngIf="showMenu && !showCharges">
                                    <div class="sb-item animated slideInRight"
                                        (click)="changeView('menu');clickPage('Estacion')">
                                        <div class="d-flex align-items-start">
                                            <div style="padding: 10px 13px;" class="sb-item-icon">
                                                <i class="fa fa-user"></i>
                                            </div>
                                            <div class="p-l-10">
                                                <div class="sb-item-title">Mi estación</div>
                                                <div class="sb-item-desc">Agrega o actualiza los datos de tu estación.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="sb-item-next"><i class="fa fa-chevron-right"></i></div>
                                    </div>

                                    <div class="sb-item animated slideInRight"
                                        (click)="close('usuarios');clickPage('Usuarios');resetMarketIntelligence()">
                                        <div class="d-flex align-items-start">
                                            <div class="sb-item-icon"><i class="fa fa-users"></i></div>
                                            <div class="p-l-10">
                                                <div class="sb-item-title">Usuarios</div>
                                                <div class="sb-item-desc">Invita y gestiona los usuarios con acceso a
                                                    GasPremia.</div>
                                            </div>
                                        </div>
                                        <div class="sb-item-next"><i class="fa fa-chevron-right"></i></div>
                                    </div>

                                    <div class="sb-item animated slideInRight"
                                        (click)="changeView('suscripcion');clickPage('Suscripcion')">
                                        <div class="d-flex align-items-start">
                                            <div class="sb-item-icon"><i class="fa fa-credit-card"></i></div>
                                            <div class="p-l-10">
                                                <div class="sb-item-title">Suscripción</div>
                                                <div class="sb-item-desc">Accede a la información y las facturas de tu
                                                    suscripción.</div>
                                            </div>
                                        </div>
                                        <div class="sb-item-next"><i class="fa fa-chevron-right"></i></div>
                                    </div> 

                                    <div class="sb-item animated slideInRight"
                                        (click)="close('lista-negra');clickPage('ListaNegra')"
                                        *ngIf="page==='GasPremia'">
                                        <div class="d-flex align-items-start">
                                            <div class="sb-item-icon"><i class="fa fa-list-alt"></i></div>
                                            <div class="p-l-10">
                                                <div class="sb-item-title">Lista negra</div>
                                                <div class="sb-item-desc">Lista de clientes no autorizados a obtener
                                                    puntos.</div>
                                            </div>
                                        </div>
                                        <div class="sb-item-next"><i class="fa fa-chevron-right"></i></div>
                                    </div>

                                    <div class="sb-item animated slideInRight"
                                        (click)="changeCharges();clickPage('SubirCargas')" *ngIf="page==='GasPremia'">
                                        <div class="d-flex align-items-start">
                                            <div class="sb-item-icon"><i class="fa fa-upload"></i></div>
                                            <div class="p-l-10">
                                                <div class="sb-item-title">Subir cargas</div>
                                                <div class="sb-item-desc">Archivo con todas las cargas realizadas del
                                                    día anterior.</div>
                                            </div>
                                        </div>
                                        <div class="sb-item-next"><i class="fa fa-chevron-right"></i></div>
                                    </div>
                                </div>
                                <div class="modal-body animated slideInRight" style="padding: 0rem 1rem !important"
                                    *ngIf="!showCharges && !showMenu && !showSuscripcion">
                                    <div class="sb-item-next back" (click)="resetView()"><i
                                            class="fa fa-chevron-left"></i> Atrás</div>

                                    <form class="form-horizontal form-material full" [formGroup]="stationForm"
                                        (ngSubmit)="editStation()">
                                        <div class="form-group">
                                            <label for="name">Nombre de la estación</label>
                                            <input class="all" type="text" formControlName="name"
                                                [attr.disabled]="true">
                                            <label for="cre">Permiso CRE</label>
                                            <input class="all" type="text" formControlName="cre" [attr.disabled]="true">
                                            <label for="location">Dirección de la estación</label>
                                            <input class="all" type="text" formControlName="location">
                                            <label for="location">Sitio web de la estación</label>
                                            <input class="all" type="text" formControlName="website">
                                            <div class="half">
                                                <div class="left">
                                                    <label for="phone">Teléfono de la estación</label><br>
                                                    <input class="all" type="text" formControlName="phone">
                                                </div>
                                                <div class="rigth">
                                                    <label for="website">Email de la estación</label>
                                                    <input class="all" type="text" formControlName="email">
                                                </div>
                                            </div>
                                            <div class="half">
                                                <div class="left">
                                                    <label for="brand">Marca de la estación</label><br>
                                                    <select class="all" type="text" formControlName="brand">
                                                        <option *ngFor="let brand of brands" [ngValue]="brand.name">
                                                            {{brand.name}}</option>
                                                    </select>
                                                </div>
                                                <div class="rigth">
                                                    <label for="num_dispensaries"># de dispensarios</label><br>
                                                    <input class="all" type="text" formControlName="num_dispensaries">
                                                </div>
                                            </div>
                                            <div class="half">
                                                <div class="left">
                                                    <label for="inauguration">Año de apertura</label><br>
                                                    <input class="all" type="text" formControlName="inauguration">
                                                </div>
                                                <div class="rigth">
                                                    <label for="type_conv_store">Tienda de Conveniencia</label><br>
                                                    <select class="all" type="text" formControlName="type_conv_store">
                                                        <option *ngFor="let store of stores" [ngValue]="store.name">
                                                            {{store.name}}</option>
                                                    </select>
                                                </div>
                                            </div>

                                        </div>
                                        <button type="submit" class="btn-green medium" value="submit"
                                            [disabled]="!stationForm.valid">Guardar</button>
                                    </form>
                                </div>

                                <div class="modal-body animated slideInRight" style="padding: 0rem 1rem !important"
                                    *ngIf="!showCharges && !showMenu && showSuscripcion">
                                    <div class="sb-item-next back" (click)="resetView()"><i
                                            class="fa fa-chevron-left"></i> Atrás</div>
                                    <br>
                                    <h4 class="title-sus">Suscripción</h4>
                                    <h5 class="subtitle-sus">Lista de planes y beneficios que tienes con tu membresía
                                    </h5>
                                    <br>
                                    <div class="pricing-columns-data">
                                        <div class="plan-container regular">
                                            <div class="plan">
                                                <h5 class="plan-regular">Plan Regular</h5>
                                                <h6 class="plan-included">Costo:</h6>
                                                <div class="plan-price">
                                                    <span class="monthprice-crossed" style="display: inline;">
                                                        <input type="checkbox" id="plan1" [(ngModel)]="plan1"
                                                            (ngModelChange)="selectPlan(1)"> <label for="plan1">$ 950
                                                            + IVA <strong>/</strong> Mensual</label>
                                                    </span>
                                                    <br>
                                                    <span class="monthprice-crossed" style="display: inline;">
                                                        <input type="checkbox" id="plan2" [(ngModel)]="plan2"
                                                            (change)="selectPlan(2)"> <label for="plan2">$ 2,500 + IVA
                                                            <strong>/</strong> Trimestral</label>
                                                    </span>
                                                </div>
                                                <button class="btn plan-buy" (click)="modalBuy()">COMPRAR AHORA</button>
                                                <h6 class="plan-included">Beneficios:</h6>
                                                <ul class="plan-features-list">
                                                    <li>
                                                        <epy-tooltip placement="left" class="price">
                                                            <span class="difference" slot="trigger"> <i
                                                                    class="fa fa-check-circle check"></i>
                                                                Acceso computadora + celular</span>
                                                            Monitorea el precio de tus <strong>competidores</strong>
                                                            desde cualquier
                                                            dispositivo computadora, celular o tablet.
                                                        </epy-tooltip>
                                                    </li>
                                                    <li>
                                                        <epy-tooltip placement="left">
                                                            <span class="difference" slot="trigger"> <i
                                                                    class="fa fa-check-circle check"></i>
                                                                Sección Precio Competencia</span>
                                                            Monitorea el <strong>precio actual</strong> de tus
                                                            competidores en cualquier momento.
                                                        </epy-tooltip>
                                                    </li>
                                                    <li>
                                                        <epy-tooltip placement="left">
                                                            <span class="difference" slot="trigger"> <i
                                                                    class="fa fa-check-circle check"></i>
                                                                Sección Precio Compra</span>
                                                            Ahorra entre <strong>$40,000</strong> y
                                                            <strong>$50,000</strong> al mes al saber el Precio de Compra
                                                            <br>desde el día anterior, optimizando cuándo solicitar
                                                            una pipa a un precio menor.

                                                        </epy-tooltip>
                                                    </li>
                                                    <li>
                                                        <epy-tooltip placement="left">
                                                            <span class="difference" slot="trigger"> <i
                                                                    class="fa fa-check-circle check"></i>
                                                                Alertas de Cambios</span>
                                                            Recibe notificaciones vía email y whatsapp de cualquier
                                                            movimiento de tus <strong>competidorres</strong>.
                                                        </epy-tooltip>
                                                    </li>
                                                    <li>
                                                        <epy-tooltip placement="left">
                                                            <span class="difference" slot="trigger"> <i
                                                                    class="fa fa-check-circle check"></i>
                                                                Usuarios Adicionales</span>
                                                            Podrás añadir <strong>5</strong> usuarios para que ocupen
                                                            <strong>GasPrecio</strong>
                                                        </epy-tooltip>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="plan-container premium">
                                            <div class="plan">
                                                <h5 class="plan-premium">Plan Premium</h5>
                                                <h6 class="plan-included">Costo:</h6>
                                                <div class="plan-price">
                                                    <span class="monthprice-crossed" style="display: inline;">
                                                        <input type="checkbox" id="plan3" [(ngModel)]="plan3"
                                                            (change)="selectPlan(3)"> <label for="plan3">$ 2,950 + IVA
                                                            <strong>/</strong>
                                                            Mensual</label>
                                                    </span>
                                                    <br>
                                                    <span class="monthprice-crossed" style="display: inline;">
                                                        <input type="checkbox" id="plan4" [(ngModel)]="plan4"
                                                            (change)="selectPlan(4)"> <label for="plan4">$ 7,500 + IVA
                                                            <strong>/</strong>
                                                            Trimestral</label>
                                                    </span>
                                                </div>
                                                <button class="btn plan-buy" (click)="modalBuy()">COMPRAR AHORA</button>
                                                <h6 class="plan-included">Beneficios:</h6>
                                                <ul class="plan-features-list">
                                                    <li>
                                                        <epy-tooltip placement="left" class="price">
                                                            <span class="difference" slot="trigger"> <i
                                                                    class="fa fa-check-circle check"></i>
                                                                Acceso computadora + celular</span>
                                                            Monitorea el precio de tus <strong>competidores</strong>
                                                            desde cualquier
                                                            dispositivo computadora, celular o tablet.
                                                        </epy-tooltip>
                                                    </li>
                                                    <li>
                                                        <epy-tooltip placement="left">
                                                            <span class="difference" slot="trigger"> <i
                                                                    class="fa fa-check-circle check"></i>
                                                                Sección Precio Competencia</span>
                                                            Monitorea el <strong>precio actual</strong> de tus
                                                            competidores en cualquier momento.
                                                        </epy-tooltip>
                                                    </li>
                                                    <li>
                                                        <epy-tooltip placement="left">
                                                            <span class="difference" slot="trigger"> <i
                                                                    class="fa fa-check-circle check"></i>
                                                                Sección Precio Compra</span>
                                                            Ahorra entre <strong>$40,000</strong> y
                                                            <strong>$50,000</strong> al mes al saber el Precio de Compra
                                                            <br>desde
                                                            el día anterior, optimizando cuándo solicitar
                                                            una pipa a un precio menor.

                                                        </epy-tooltip>
                                                    </li>
                                                    <li>
                                                        <epy-tooltip placement="left">
                                                            <span class="difference" slot="trigger"> <i
                                                                    class="fa fa-check-circle check"></i>
                                                                Sección Precio Recomendado</span>
                                                            Conoce el precio que te permita <strong>maximizar tus
                                                                ganancias</strong>, dado el precio de tus competidores.

                                                        </epy-tooltip>
                                                    </li>
                                                    <li>
                                                        <epy-tooltip placement="left">
                                                            <span class="difference" slot="trigger"> <i
                                                                    class="fa fa-check-circle check"></i>
                                                                Alertas de Cambios</span>
                                                            Recibe notificaciones vía email y whatsapp de cualquier
                                                            movimiento de tus <strong>competidorres</strong>.
                                                        </epy-tooltip>
                                                    </li>
                                                    <li>
                                                        <epy-tooltip placement="left">
                                                            <span class="difference" slot="trigger"> <i
                                                                    class="fa fa-check-circle check"></i>
                                                                Usuarios Adicionales</span>
                                                            Podrás añadir <strong>5</strong> usuarios para que ocupen
                                                            <strong>GasPrecio</strong>.
                                                        </epy-tooltip>
                                                    </li>
                                                    <li>
                                                        <epy-tooltip placement="left">
                                                            <span class="difference" slot="trigger"> <i
                                                                    class="fa fa-check-circle check"></i>
                                                                Newsletter Mensual</span>
                                                            Recibe un boletín mensual con los cambios más importantes en
                                                            precio a nivel Nacional.
                                                        </epy-tooltip>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <h4 class="title-sus" *ngIf="!waitList">GasPremia</h4>
                                    <input *ngIf="!waitList" type="checkbox" id="waitList" [(ngModel)]="waitList"
                                        (ngModelChange)="modalWaitList()"> <label *ngIf="!waitList" class="subtitle-sus"
                                        for="waitList">Solicitar lista de espera para el lanzamiento</label>
                                </div>

                                <div class="modal-body animated slideInRight" *ngIf="showCharges && !showMenu">
                                    <div class="sb-item-next back" (click)="resetView()"><i
                                            class="fa fa-chevron-left"></i> Atrás</div>
                                    <br><br><br><br><br><br><br><br>
                                    <form class="form-horizontal form-material full" [formGroup]="newForm"
                                        (ngSubmit)="uploadFile()">
                                        <div class="form-group">
                                            <div class="border-dotted">
                                                <input type="file" accept=".xlsx, .xls" name="file-4[]" id="file"
                                                    style="display: none;" class="inputfile inputfile-3"
                                                    (change)="loadFile($event)" formControlName="file" />
                                                <label for="file"><span>Adjuntar archivo .xlsx (Excel)</span></label>
                                            </div>
                                        </div>
                                        <button type="submit" class="btn-green medium" value="submit"
                                            [disabled]="!newForm.valid">Subir</button>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </li>
                 ============================================================== -->
                <!-- End Settings -->
                <!-- ============================================================== -->

                <!-- ============================================================== -->
                <!-- Messages -->
                <!-- ============================================================== -->
                <li class="nav-item dropdown" *ngIf="rol==='Administrador' || rol === 'Dios'"
                    (click)="clickPage('Notificaciones')">
                    <a class="nav-link dropdown-toggle waves-effect waves-dark" id="2" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <i class="mdi mdi-email"></i>
                        <div class="notify" *ngIf="notifications">
                            <span class="point"></span>
                        </div>
                    </a>
                    <div class="dropdown-menu mailbox dropdown-menu-right animated fadeIn" aria-labelledby="2">
                        <ul>
                            <li>
                                <div class="drop-title">Notificaciones</div>
                            </li>
                            <li>
                                <div class="message-center">
                                    <a *ngFor="let alert of alerts" (click)="goTo(alert.type, alert)"
                                        (mouseover)="alertRead(alert._id, alert.click)">
                                        <div class="user-img">
                                            <i class="fa fa-list-ol" style="font-size: 22px;color:gray"></i>
                                            <span class="profile-status pull-right"
                                                [ngClass]="alert.click ? 'offline' : 'online'"></span>
                                        </div>
                                        <div class="mail-contnet">
                                            <h6 style="margin-bottom: 0;">{{alert.station}}</h6>
                                            <span class="mail-desc">{{alert.text}}</span>
                                            <span class="time">{{alert.date | date:'dd/MMM/yy HH:mm'}} hrs.</span>
                                        </div>
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </li>
                <!-- ============================================================== -->
                <!-- End Messages -->
                <!-- ============================================================== -->

                <!-- Station -->
                <!-- ============================================================== -->
                <li class="nav-item dropdown" (click)="clickPage('Estaciones')" *ngIf="(page$ | async) === 'Monitor'">
                    <a class="nav-link dropdown-toggle waves-effect waves-dark" href="" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false"> {{clienteActual}}</a>
                    <div class="dropdown-menu client-list dropdown-menu-right animated bounceInDown">
                        <input type="text" placeholder="Buscar estación..." (ngModelChange)="filterItem($event)"
                            [(ngModel)]="filterText" class="search-station">
                        <ng-container *ngFor="let client of clientsList">
                            <a class="dropdown-item" (click)="changeClient(client);">
                                {{capitalizeFirstLetter(client.alias)}}</a>
                        </ng-container>
                    </div>
                </li>

                <li class="nav-item dropdown" (click)="clickPage('Estaciones')" *ngIf="(page$ | async) === 'General' || (page$ | async) === 'Mercado'">
                    <a class="nav-link dropdown-toggle waves-effect waves-dark" href="" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false"> {{clienteActual}}</a>
                    <div class="dropdown-menu client-list dropdown-menu-right animated bounceInDown">

                        <input type="text" placeholder="Buscar estación..." (ngModelChange)="filterItem($event)"
                            [(ngModel)]="filterText" class="search-station">

                        <ng-container *ngFor="let client of clientsList">
                            <a class="dropdown-item" (click)="changeClientG(client);">
                                {{capitalizeFirstLetter(client.alias)}}</a>
                        </ng-container>
                    </div>
                </li>

                <!-- Profile -->
                <!-- ============================================================== -->
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle waves-effect waves-dark" href="" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <!-- <img src="assets/images/users/1.jpg" alt="user" class="profile-pic" /> -->
                        <p style="margin-bottom: 0;" [attr.data-letters]="letters"></p>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right animated fadeIn">
                        <ul class="dropdown-user">
                            <li>
                                <div class="dw-user-box">
                                    <div class="u-text">
                                        <h4>{{name}}</h4>
                                        <p class="text-muted">{{email}}</p>
                                    </div>
                                </div>
                            </li>
                            <li role="separator" class="divider"></li>
                            <li><a (click)="logout();clickPage('Salir')" [routerLink]="'/login'"><i
                                        class="fa fa-power-off"></i> Salir</a></li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</header>
<!-- ============================================================== -->
<!-- End Topbar header -->
<!-- ============================================================== -->