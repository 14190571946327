import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roundDecimals'
})
export class RoundDecimalsPipe implements PipeTransform {

  transform(value: string | number, decimals = 2): string {
    if (typeof value === 'number') return value.toFixed(decimals);
    return parseFloat(value).toFixed(decimals);
  }

}
