import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percentageParser'
})
export class PercentageParserPipe implements PipeTransform {

  transform(value: string | number | undefined, decimals = 2): unknown {
    if (typeof value === 'number') {
      if (value < 0) return `- ${(Math.abs(value).toFixed(decimals))} %`;
      return `${(value.toFixed(decimals))} %`;
    }
    return value?.includes('-') ? (value) : value?.includes('%') ? (value) : (value) ? `${(value)} %` : '-';
  }

}
