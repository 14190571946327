import { Pipe, PipeTransform } from '@angular/core';
import { FormatLargeNumberPipe } from './format-large-number.pipe';

@Pipe({
  name: 'moneyParser'
})
export class MoneyParserPipe implements PipeTransform {

  constructor (private readonly formatLargeNumberPipe: FormatLargeNumberPipe) {}

  transform(value: string | number | undefined, decimals = 2): string {
    if (typeof value === 'number') {
      if (value < 0) return `- $ ${this.formatLargeNumberPipe.transform(Math.abs(value).toFixed(decimals))}`;
      return `$ ${this.formatLargeNumberPipe.transform(value.toFixed(decimals))}`;
    }
    return value?.includes('-') ? this.formatLargeNumberPipe.transform(value) : value?.includes('$') ? this.formatLargeNumberPipe.transform(value) : this.formatLargeNumberPipe.transform(value) ? `$ ${this.formatLargeNumberPipe.transform(value)}` : '-';
  }

}
