import { Component, Input, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-product-total',
  templateUrl: './select-product-total.component.html',
  styleUrls: ['./select-product-total.component.scss']
})
export class SelectProductTotalComponent implements  OnDestroy {

  @Input() page: any;
  public items: any[] = [
    { name: 'Total', value: 'total' },
    { name: 'Regular', value: 'regular' },
    { name: 'Premium', value: 'premium' },
    { name: 'Diesel', value: 'diesel' }
  ];

  public productTypeTotal: any = [];
  public ngxDisabled = false;
  productTypeTotal$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService
  ) {
    this.productTypeTotal$ = this.sharedService.sharedProductTypeTotal.subscribe(productTypeTotal => {
      if (this.productTypeTotal !== productTypeTotal) {
        this.productTypeTotal = productTypeTotal;
      }
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnInit() {
    if (this.page === 'stock') {
      this.items = [
        { name: '3 Productos', value: 'total' },
        { name: 'Regular', value: 'regular' },
        { name: 'Premium', value: 'premium' },
        { name: 'Diesel', value: 'diesel' }
      ];
    } else {
      this.items = [
        { name: 'Total', value: 'total' },
        { name: 'Regular', value: 'regular' },
        { name: 'Premium', value: 'premium' },
        { name: 'Diesel', value: 'diesel' }
      ];
    }
  }

  ngOnDestroy() {
    this.productTypeTotal$.unsubscribe();
  }

  changeProductType(event: any) {
    this.productTypeTotal = event;
    this.sharedService.nextProductTypeTotal = this.productTypeTotal;
  }
}
