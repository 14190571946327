<div class="graphic-left">
    <div class="header-c">
        Ventas - Venta Desestacionalizadas
        <epy-tooltip placement="top" style="width: 18px;display: inline-block;">
            <span class="difference" slot="trigger">
                <i class="fa fa-info-circle info" aria-hidden="true"></i>
            </span>
            Promedio de litros vendidos por estación.
        </epy-tooltip>
    </div>
</div>

<div *ngIf="reqst===1" class="text-center loader-container">
    <div class="spinner-grow align-middle text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>

<div class="flex-item" *ngIf="reqst===2">
    <div class="graphic-left">
        <highcharts-chart [Highcharts]="Highcharts" [options]="chartOptions"
            style="width: 100%;height: 310px;display: block;overflow: hidden;"></highcharts-chart>
    </div>
</div>