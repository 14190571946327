import { Component } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import * as moment from 'moment-timezone';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';

@Component({
  selector: 'app-select-month',
  templateUrl: './select-month.component.html',
  styleUrls: ['./select-month.component.scss']
})
export class SelectMonthComponent {

  public items: any[] = [];
  public months: any = [this.gasprecioService.capitalizeFirstLetter(moment().format('MMMM YYYY'))];

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService,
    private gasprecioService: GasprecioService
  ) {
    const startDate = moment('2021-01-01');
    const endDate = moment();
    if (startDate < endDate) {
      const date = startDate.startOf('month');
      while (date <= endDate.endOf('month')) {
        this.items.push({
          name: this.gasprecioService.capitalizeFirstLetter(date.format('MMMM YYYY')),
          startDate: date.startOf('month').format('YYYY-MM-DD'),
          endDate: date.endOf('month').format('YYYY-MM-DD')
        });
        date.add(1, 'month');
      }
      this.items.reverse();
    }
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  changeMonthsAgo(event: any) {
    this.months = event;
    this.items.map((e) => {
      if (e.name === event) {
        this.sharedService.nextDateStart = e.startDate;
        this.sharedService.nextDateEnd = e.endDate;
      }
    });

  }
}
