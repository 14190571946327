import * as moment from 'moment-timezone';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import { Router, Event as NavigationEvent, NavigationEnd } from '@angular/router';
import Highcharts from 'highcharts';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Component({
  selector: 'app-chart-margin-volumen',
  templateUrl: './chart-margin-volumen.component.html',
  styleUrls: ['./chart-margin-volumen.component.scss']
})
export class ChartMarginVolumenComponent implements OnInit, OnDestroy {

  @Input() station: any = false;
  date: any;
  days = 30;
  days$!: Subscription;
  event$!: Subscription;
  idGroup = '60afa99655f4ee369d1df409';
  idGroup$!: Subscription;
  importants;
  importants$: Subscription;
  months = 12;
  months$!: Subscription;
  period = 'day';
  period$!: Subscription;
  productType = 'regular';
  productType$!: Subscription;
  purchaseMargin;
  reqst = 0;
  rol;
  route;
  selectedCluster;
  selectedCluster$!: Subscription;
  selectedCluster2;
  selectedCluster2$!: Subscription;
  selectedCluster3;
  selectedCluster3$!: Subscription;
  selectedCluster4;
  selectedCluster4$!: Subscription;
  showRendichicas = false;
  showScale = true;
  showTooltip = true;
  tendency: any = 'avg_selling_price';
  typeVolume: string = 'avg';
  volumen: any;
  volumenDif;
  weeks = 12;
  weeks$!: Subscription;
  zoneSelected = null;
  zoneSelected$: Subscription;

  public items: any[] = [
    { name: 'Promedio', value: 'avg' },
    { name: 'Acumulado', value: 'accumulated' }
  ];

  public statisticSelected: any = [];
  public ngxDisabled = true;
  statisticSelected$: Subscription;

  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {
    credits: {
      enabled: false
    },
    chart: {
      type: 'line'
    },
    title: {
      text: `Volumen de Venta - Margen $`,
      style: {
        fontSize: '0'
      }
    },
    xAxis: {
      categories: [],
      gridLineWidth: 1,
      gridLineDashStyle: 'LongDash',
      title: {
        text: 'Fecha',
        style: {
          fontSize: '0'
        }
      },
      labels: {
        style: {
          fontSize: '11px',
        },
        rotation: -60,
        y: 14
      }
    },
    yAxis: [
      {
        title: {
          text: 'Volumen de Venta',
          style: {
            fontSize: '0'
          }
        },
        labels: {
          format: '{value:,.0f} lts.',
          style: {
            fontSize: '11px'
          }
        },
        tickAmount: 10,
        gridLineDashStyle: 'LongDash'
      },
      {
        title: {
          text: 'Margen $',
          style: {
            fontSize: '0'
          }
        },
        labels: {
          format: '$ {value:.2f}',
          style: {
            fontSize: '11px'
          }
        },
        opposite: true,
        tickAmount: 10,
        gridLineDashStyle: 'LongDash'
      }
    ],
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'top',
      itemDistance: 10,
      y: 1,
      padding: 1,
      itemMarginTop: 1,
      itemMarginBottom: 1,
      itemHoverStyle: {
        color: '#00E07B',
      }
    },
    tooltip: {
      split: true,
    },
    plotOptions: {
      column: {
        animation: {
          duration: 1000
        },
        stacking: 'normal'
      }
    },
    exporting: {
      filename: `Volumen de Venta - Margen $`,
      sourceWidth: 1200,
      sourceHeight: 800,
    },
    navigation: {
      buttonOptions: {
        align: 'right',
        y: -10
      }
    },
    series: []
  };

  constructor(
    private gasprecioService: GasprecioService,
    private sharedService: SharedService,
    private router: Router,
    public sanitizer: DomSanitizer
  ) {
    this.event$ = this.router.events.subscribe((event: NavigationEvent) => {
      if (event instanceof NavigationEnd) {
        this.route = /[^/]*$/.exec(event.url)[0];
      }
    });
    this.rol = localStorage.getItem('rol');
    this.idGroup = (this.rol === 'Dios') ? localStorage.getItem('group') || '60afa99655f4ee369d1df409' : localStorage.getItem('group');
    this.showRendichicas = this.idGroup === '615efe30b318ce63b8971986' ? true : false;
    this.productType$ = this.sharedService.sharedProductTypeTotal.subscribe(productType => {
      if (this.productType !== productType) {
        this.productType = productType;
        if (this.reqst > 1) {
          this.setData();
        }
      }
    });
    this.idGroup$ = sharedService.sharedGroup.subscribe((idGroup: string) => {
      if (idGroup !== this.idGroup) {
        this.idGroup = idGroup;
        this.showRendichicas = this.idGroup === '615efe30b318ce63b8971986' ? true : false;
        if (this.reqst > 1) {
          this.zoneSelected = '';
          this.getDashboardVolumenPurchaseMargin();
        }
      }
    });

    this.zoneSelected$ = sharedService.sharedZoneDashboard.subscribe((zone: string) => {
      if (zone === 'Total') {
        this.zoneSelected = null;
        if (zone !== this.zoneSelected) {
          this.getDashboardVolumenPurchaseMargin();
        }
      } else {
        if (zone !== this.zoneSelected) {
          this.zoneSelected = zone;
          this.getDashboardVolumenPurchaseMargin();
        }
      }
    });

    this.period$ = this.sharedService.sharedPeriodDashboard.subscribe(period => {
      if (period !== this.period) {
        this.period = period;
        if (this.reqst > 1) {
          this.getDashboardVolumenPurchaseMargin();
        }
      }
    });

    this.days$ = this.sharedService.sharedDaysDashboard.subscribe(days => {
      if (days !== this.days) {
        this.days = days;
        if (this.reqst > 1) {
          this.getDashboardVolumenPurchaseMargin();
        }
      }
    });

    this.weeks$ = this.sharedService.sharedWeeksAgo.subscribe(weeks => {
      if (weeks !== this.weeks) {
        this.weeks = weeks;
        if (this.reqst > 1) {
          this.getDashboardVolumenPurchaseMargin();
        }
      }
    });

    this.months$ = this.sharedService.sharedMonthsAgo.subscribe(months => {
      if (months !== this.months) {
        this.months = months;
        if (this.reqst > 1) {
          this.getDashboardVolumenPurchaseMargin();
        }
      }
    });

    this.importants$ = sharedService.sharedImportants.subscribe((importants: string) => {
      if (this.importants !== importants) {
        this.importants = importants;
        if (this.reqst > 1) {
          this.getDashboardVolumenPurchaseMargin();
        }
      }
    });

    this.selectedCluster$ = sharedService.sharedSelectedComplementClusters.subscribe((selectedCluster: any) => {
      if (selectedCluster !== this.selectedCluster) {
        this.selectedCluster = selectedCluster;
        if (this.reqst > 1) {
          this.getDashboardVolumenPurchaseMargin();
        }
      }
    });

    this.selectedCluster2$ = sharedService.sharedSelectedComplementClusters2.subscribe((selectedCluster2: any) => {
      if (selectedCluster2 !== this.selectedCluster2) {
        this.selectedCluster2 = selectedCluster2;
        if (this.reqst > 1) {
          this.getDashboardVolumenPurchaseMargin();
        }
      }
    });

    this.selectedCluster3$ = sharedService.sharedSelectedComplementClusters3.subscribe((selectedCluster3: any) => {
      if (selectedCluster3 !== this.selectedCluster3) {
        this.selectedCluster3 = selectedCluster3;
        if (this.reqst > 1) {
          this.getDashboardVolumenPurchaseMargin();
        }
      }
    });

    this.selectedCluster4$ = sharedService.sharedSelectedComplementClusters4.subscribe((selectedCluster4: any) => {
      if (selectedCluster4 !== this.selectedCluster4) {
        this.selectedCluster4 = selectedCluster4;
        if (this.reqst > 1) {
          this.getDashboardVolumenPurchaseMargin();
        }
      }
    });
  }

  ngOnInit() {
    this.getDashboardVolumenPurchaseMargin();
  }

  ngOnDestroy(): void {
    this.days$.unsubscribe();
    this.event$.unsubscribe();
    this.idGroup$.unsubscribe();
    this.importants$.unsubscribe();
    this.months$.unsubscribe();
    this.period$.unsubscribe();
    this.productType$.unsubscribe();
    this.selectedCluster$.unsubscribe();
    this.selectedCluster2$.unsubscribe();
    this.selectedCluster3$.unsubscribe();
    this.selectedCluster4$.unsubscribe();
    this.weeks$.unsubscribe();
    this.zoneSelected$.unsubscribe();
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  changeVariable(event: any) {
    this.typeVolume = event;
    if (this.reqst > 1) {
      this.getDashboardVolumenPurchaseMargin();
    }
  }

  getDashboardVolumenPurchaseMargin() {
    this.reqst = 1;
    let typeValue = 'value';
    const periodMap = {
      'day': this.days,
      'week': this.weeks,
      'month': this.months
    };
    const n = periodMap[this.period];
    typeValue = (typeof n === 'number') ? 'value' : 'date';
    const url = this.station ? this.gasprecioService.getDashboardVolumenPurchaseMarginStation(this.period, typeValue, this.typeVolume, n) : this.gasprecioService.getDashboardVolumenPurchaseMargin(this.idGroup, this.zoneSelected, this.period, this.importants, typeValue, this.typeVolume, n, this.selectedCluster, this.selectedCluster2, this.selectedCluster3, this.selectedCluster4);
    url.subscribe({
      next: (data: any) => {
        const info = data;
        this.volumen = info['volume'];
        this.purchaseMargin = info['purchase_margin'];
        this.volumenDif = info['volume_comparison'];
        this.tendency = info['adjusted_volume'];
        this.setData();
      }, complete: () => {
        this.reqst = 2;
      }, error: () => {
        this.reqst = 3;
      }
    });
  }

  setData() {
    this.reqst = 1;
    const color = this.gasprecioService.getColorString(this.gasprecioService.colorsProducts[this.gasprecioService.getProductTypeIndex(this.productType)]);
    const colorGold = this.gasprecioService.getColorString(this.gasprecioService.colors[0]);
    const colorTendency = this.productType === 'regular' ? '#006400' : this.productType === 'premium' ? '#A00000' : this.productType === 'diesel' ? '#001F20' :'#005B8C ';

    const minVolume = Math.min(...this.volumen
      .map((comp: any) => +comp[this.productType])
      .filter((value: number) => value !== null && value !== 0)
    );

    const tarChartData: any = {
      name: 'Margen $',
      data: this.purchaseMargin.map((v: any) => {
        if (v[this.productType] === null) {
          return null; // Devuelve null si el valor es null
        } else {
          return +v[this.productType]; // Devuelve el valor convertido a número
        }
      }),
      color: colorGold,
      lineWidth: 2,
      marker: { radius: 4, symbol: 'diamond' },
      yAxis: 1,
      tooltip: {
        pointFormatter: function () {
          return '<b>' + this.series.name + ': $ ' + this.y.toFixed(2) + '</b>';
        }
      },
    };
    
    let tendencyChartData: any;

    if(this.tendency.length > 0) {
      tendencyChartData = {
        name: 'Tendencia',
        data: this.tendency.map((v: any) => {
          if (v[this.productType] === null) {
            return null; // Devuelve null si el valor es null
          } else {
            return +v[this.productType]; // Devuelve el valor convertido a número
          }
        }),
        lineWidth: 3,
        color: colorTendency,
        dashStyle: 'ShortDashDot',
        marker: {
          radius: 1
        },
        yAxis: 0,
        tooltip: {
          pointFormatter: function () {
            return '<b>' + this.series.name + ': ' + this.y.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' lts. </b>';
          }
        },
      };
    }

    let volumenChartData: any;
    const lineXaxisLegendGenerator = {
      day: (v: any) => this.gasprecioService.parseDateDM(v.date),
      week: (v: any) => v.date_alias,
      month: (v: any) => this.gasprecioService.parseDateM(v.date)
    };

    const lineXaxisLegend = this.volumen.map(lineXaxisLegendGenerator[this.period]);
    this.chartOptions['xAxis']['categories'] = lineXaxisLegend;
    let numberLabels = this.chartOptions['xAxis']['categories'].length;
    const borderW = numberLabels >= 90 ? 0 : 2;
    if (this.productType === 'total') {
      this.showScale = true;
      const colors = [
        this.gasprecioService.getColorString(this.gasprecioService.colorsProducts[this.gasprecioService.getProductTypeIndex('regular')]),
        this.gasprecioService.getColorString(this.gasprecioService.colorsProducts[this.gasprecioService.getProductTypeIndex('premium')]),
        this.gasprecioService.getColorString(this.gasprecioService.colorsProducts[this.gasprecioService.getProductTypeIndex('diesel')])
      ];

      const productTypes = ['regular', 'premium', 'diesel'];

      volumenChartData = productTypes.map((type, index) => ({
        name: this.gasprecioService.capitalizeFirstLetter(type),
        type: 'column',
        data: this.volumen.map((comp: any) => +comp[type]),
        color: colors[index],
        borderColor: colors[index],
        borderWidth: borderW,
        yAxis: 0,
        stack: 'total',
        tooltip: {
          pointFormatter: function () {
            return '<b>' + this.series.name + ': ' + this.y.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' lts.</b>';
          }
        }
      }));
    } else {
      volumenChartData = [{
        name: 'Volumen de Venta',
        type: 'column',
        data: this.volumen.map((v: any) => {
          if (v[this.productType] === null) {
            return null; // Devuelve null si el valor es null
          } else {
            return +v[this.productType]; // Devuelve el valor convertido a número
          }
        }),
        color: color,
        borderColor: color,
        borderWidth: borderW,
        yAxis: 0,
        tooltip: {
          pointFormatter: function () {
            return '<b>' + this.series.name + ': ' + this.y.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' lts.</b>';
          }
        }
      }];
    }

    let diffPct2, diffPct1, diff2, diff1, labelDiff2, labelDiff1;
    if (this.volumenDif.length > 0 ) {
      diffPct2 = this.volumenDif[1]['diff_pct_' + this.productType] !== null ? this.volumenDif[1]['diff_pct_' + this.productType].toFixed(0) : '-';
      diffPct1 = this.volumenDif[2]['diff_pct_' + this.productType] !== null ? this.volumenDif[2]['diff_pct_' + this.productType].toFixed(0) : '-';
      diff2 = this.volumenDif[1]['diff_' + this.productType] !== null ? this.volumenDif[1]['diff_' + this.productType].toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '-';
      diff1 = this.volumenDif[2]['diff_' + this.productType] !== null ? this.volumenDif[2]['diff_' + this.productType].toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '-';
      labelDiff2 = this.period === 'month' ? moment().subtract(1, 'month').tz('America/Mexico_City').format('MMMM') : this.chartOptions['xAxis']['categories'][numberLabels - 2];
      labelDiff1 = this.period === 'month' ? moment().tz('America/Mexico_City').format('MMMM') : this.chartOptions['xAxis']['categories'][numberLabels - 1];
      let pos1 = numberLabels - 1;
      let pos2 = numberLabels - 2;

      let typeValue = 'value';
      const periodMap = {
        'day': this.days,
        'week': this.weeks,
        'month': this.months
      };
      const n = periodMap[this.period];
      typeValue = (typeof n === 'number') ? 'value' : 'date';
      if (typeValue === 'value') {
        if (this.period === 'week' && this.weeks === 26) {
          pos2 = numberLabels - 3;
          pos1 = numberLabels - 1;
        } else if (this.period === 'week' && this.weeks === 52) {
          pos2 = numberLabels - 4;
        } else if (this.period === 'week' && this.weeks === 104) {
          pos2 = numberLabels - 8;
        } else if (this.period === 'week' && this.weeks === 156) {
          pos2 = numberLabels - 14;
        }
      } else if (typeValue === 'date') {
        if (numberLabels < 80) {
          pos2 = numberLabels - 6;
        } else if (numberLabels < 150) {
          pos2 = numberLabels - 11;
          pos1 = numberLabels - 3;
        } else if (numberLabels < 180) {
          pos2 = numberLabels - 15;
          pos1 = numberLabels - 4;
        }
      }

      if (this.period === 'month' && (this.months === 18 || this.months === 24)) {
        pos2 = numberLabels - 4;
      } else if (this.period === 'month' && this.months === 30) {
        pos2 = numberLabels - 4;
      } else if (this.period === 'month' && this.months === 36) {
        pos2 = numberLabels - 5;
      }

      const annotations: any = [
        {
          draggable: '',
          labelOptions: {
            backgroundColor: '#CCCCCC',
            verticalAlign: 'top',
            align: 'center'
          },
          labels: [

          ]
        },
        {
          draggable: '',
          labelOptions: {
            backgroundColor: '#CCCCCC',
            verticalAlign: 'top',
            align: 'center'
          },
          labels: [
            {
              point: {
                xAxis: 0,
                yAxis: 5,
                x: pos2, // Índice de la penúltima columna (0-indexed)
                y: 0 // Valor de la penúltima columna
              },
              crop: false,
              overflow: 'justify',
              allowOverlap: true,
              distance: 1,
              shape: 'rect',
              text: `<div style="font-size: .5vw;"><strong>${labelDiff2}<br>$ ${diff2}<br>${diffPct2} %</strong></div>`
            },
            {
              point: {
                xAxis: 0,
                yAxis: 5,
                x: pos1, // Índice de la penúltima columna (0-indexed)
                y: 0 // Valor de la penúltima columna
              },
              crop: false,
              overflow: 'justify',
              distance: 1,
              allowOverlap: true,
              shape: 'rect',
              text: `<div style="font-size: .5vw;"><strong>${labelDiff1}<br>$ ${diff1}<br>${diffPct1} %</strong></div>`
            }
          ]
        }];
      this.chartOptions.annotations = this.showTooltip ? annotations : [];
    } else {
      this.chartOptions.annotations = []
    }
  

    this.chartOptions['yAxis'][0]['min'] = this.showScale ? 0 : minVolume;
    this.chartOptions.series = this.tendency.length > 0 ? [...volumenChartData, tarChartData, tendencyChartData] : [...volumenChartData, tarChartData];
    setTimeout(() => {
      this.reqst = 2;
    }, 100);
  }

  generateImageChart() {
    const canvas3: any = document.getElementById('mychart3');
    const imgData3 = canvas3.toDataURL('image/png');
    this.sharedService.nextImgData3 = imgData3;
  }

}
