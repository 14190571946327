import { Component } from '@angular/core';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-multiselect-flag',
  templateUrl: './multiselect-flag.component.html',
  styleUrls: ['./multiselect-flag.component.scss']
})
export class MultiselectFlagComponent {

  dropdownList = [
    { item_id: 'green', item_text: 'Verde' },
    { item_id: 'yellow', item_text: 'Amarillo' },
    { item_id: 'red', item_text: 'Rojo' }
  ];
  selectedItems = [];
  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Todas',
    unSelectAllText: 'Todas',
    itemsShowLimit: 1,
    allowSearchFilter: false,
    maxHeight: 140
  };
  url: string;

  constructor(
    private sharedService: SharedService,
  ) {

  }

  changeItems() {
    this.url = '';
    this.selectedItems.map((item) => {
      this.url = this.url + 'flags[]=' + item.item_id + '&';
    });

    if (this.selectedItems.length === 0) {
      this.url = 'flags[]=green&flags[]=yellow&flags[]=red&';
      this.selectedItems = [];
    }
    this.sharedService.nextComplementFlags = this.url;
  }

  allItems(items) {
    this.url = '';
    items.map((item) => {
      this.url = this.url + 'flags[]=' + item.item_id + '&';
    });

    this.sharedService.nextComplementFlags = this.url;
  }

  anyItems() {
    this.selectedItems = [];
    this.url = 'flags[]=green&flags[]=yellow&flags[]=red&';
    this.sharedService.nextComplementFlags = this.url;
  }

}
