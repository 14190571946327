import Swal from 'sweetalert2';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-create-groups',
  templateUrl: './create-groups.component.html',
  styleUrls: ['./create-groups.component.scss']
})
export class CreateGroupsComponent implements OnDestroy {

  clusters = [];
  clusterName = '';
  idCluster = '';
  id;
  idGroup = '60afa99655f4ee369d1df409';
  idGroupSub$!: Subscription;
  idStation;
  idZone = '';
  idZone$!: Subscription;
  rol;
  step = 'select';
  stationsGroup: any = [];
  stationsGroupOriginal: any = [];
  selectAll = true;
  showEnerser = false;

  constructor(
    public gasprecioService: GasprecioService,
    private sharedService: SharedService
  ) {
    this.rol = localStorage.getItem('rol');
    this.idStation = localStorage.getItem('idStation');
    this.id = localStorage.getItem('id');
    this.idGroup = (this.rol === 'Dios') ? localStorage.getItem('group') || '60afa99655f4ee369d1df409' : localStorage.getItem('group');
    this.showEnerser = this.idGroup === '643f507358715227618de808' || this.idGroup === '649216f6edc3bad5497001f9' ? true : false;
    this.idGroupSub$ = this.sharedService.sharedGroup.subscribe(idGroup => {
      if (idGroup !== this.idGroup && idGroup !== '') {
        this.idGroup = idGroup;
        this.showEnerser = this.idGroup === '643f507358715227618de808' || this.idGroup === '649216f6edc3bad5497001f9' ? true : false;
        this.getStationsGroupZone();
      }
    });

    this.idZone$ = sharedService.sharedZone.subscribe((idZone: any) => {
      if (idZone !== this.idZone) {
        this.idZone = idZone;
        this.getStationsGroupZone();
      }
    });
  }

  ngOnDestroy(): void {
    this.idZone$.unsubscribe();
    this.idGroupSub$.unsubscribe();
  }

  resetOption() {
    this.step = 'select';
    this.clusterName = '';
    this.stationsGroup.forEach(element => {
      element['selected'] = true;
    });
    this.idCluster = '';
  }

  changeOption(type, cluster?) {
    this.step = type;
    if (type === 'edit') {
      this.idCluster = cluster['_id']
      this.clusterName = cluster['name'];
      this.stationsGroup.forEach(element => {
        if (cluster['stations'].includes(element._id)) {
          element.selected = true;
        } else {
          element.selected = false;
        }
      });
    }
  }

  selectStations() {
    this.stationsGroup.forEach(element => element.selected = this.selectAll);
  }

  getStationsGroupZone() {
    const url = this.idZone ? this.gasprecioService.getStationsGroupZone(this.idGroup, this.idZone) : this.gasprecioService.getStationsGroup(this.idGroup);
    url.subscribe((inf: any) => {
      this.stationsGroupOriginal = [...inf];
      this.stationsGroupOriginal.forEach(element => {
        element['selected'] = true;
      });
      this.stationsGroup = [...inf];
      this.stationsGroup.forEach(element => {
        element['selected'] = true;
      });
    });
  }

  modelChangeFn(e) {
    this.stationsGroup.forEach(element => {
      if (e.target.checked && e.target.id === element.cre) {
        element.selected = true;
      }
    });
  }

  createCluster() {
    const json = {
      "data": {
        "cluster":
        {
          "name": this.clusterName,
          "group": this.idGroup,
          "stations": []
        }
      }
    }

    this.stationsGroupOriginal.map(element => {
      if (element['selected']) {
        json['data']['cluster']['stations'].push(element['_id'])
      }
    });

    if (json['data']['cluster']['name'].length === 0) {
      this.gasprecioService.displayMessage('warning', 'Ingresa el nombre del grupo')
    } else if (json['data']['cluster']['stations'].length === 0) {
      this.gasprecioService.displayMessage('warning', 'Selecciona al menos 1 estación para crear un grupo')
    } else {
      this.gasprecioService.createCluster(this.idGroup, json).subscribe({
        next: () => {
          this.successCreate();
        }, error: () => {
          this.gasprecioService.displayMessage('error', 'Error', 'El grupo no se ha creado con éxito')
        }
      });
    }
  }

  async successCreate() {
    Swal.fire({
      title: '¡Grupo nuevo creado!',
      text: 'Se ha creado el grupo nuevo con éxito',
      icon: 'success',
      confirmButtonColor: '#00e07b',
      confirmButtonText: 'Aceptar',
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false
    }).then((result) => {
      if (result.value) {
        window.location.reload();
      }
    });
  }

  getClusters() {
    this.gasprecioService.getClusters(this.idGroup, 'clusters').subscribe((data: any) => {
      this.clusters = data;
    });
  }

  warningDeleteCluster(idCluster) {
    Swal.fire({
      title: '¿Estás seguro?',
      text: `Al aceptar, borrarás el grupo de la lista`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#00e07b',
      cancelButtonColor: '#fa1975',
      confirmButtonText: 'Sí, borrarlo',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.deleteCluster(idCluster);
      }
    });
  }

  deleteCluster(idCluster) {
    const json = {
      "data": {
        "clusters_ids": [idCluster]
      }
    }

    this.gasprecioService.deleteCluster(this.idGroup, json).subscribe({
      next: () => {
        this.successDeleteCluster();
      }, error: () => {
        this.gasprecioService.displayMessage('error', '¡Error!', 'La pipa no se ha eliminado con éxito')
      }
    });
  }

  async successDeleteCluster() {
    Swal.fire({
      title: '¡Grupo eliminado!',
      text: 'El grupo se ha eliminado con éxito',
      icon: 'success',
      confirmButtonColor: '#00e07b',
      confirmButtonText: 'Aceptar',
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.value) {
        window.location.reload();
      }
    });
  }

  updateCluster() {
    const json = {
      "data": {
        "cluster":
        {
          "_id": this.idCluster,
          "name": this.clusterName,
          "group": this.idGroup,
          "stations": []
        }
      }
    }
    this.stationsGroupOriginal.map(element => {
      if (element['selected']) {
        json['data']['cluster']['stations'].push(element['_id'])
      }
    });

    if (json['data']['cluster']['name'].length === 0) {
      this.gasprecioService.displayMessage('warning', 'Ingresa el nombre del grupo')
    } else if (json['data']['cluster']['stations'].length === 0) {
      this.gasprecioService.displayMessage('warning', 'Selecciona al menos 1 estación para crear un grupo')
    } else {
      this.gasprecioService.createCluster(this.idGroup, json).subscribe({
        next: () => {
          this.successUpdate();
        }, error: () => {
          this.gasprecioService.displayMessage('error', 'Error', 'El grupo no se ha podido actualizar')
        }
      });
    }
  }

  async successUpdate() {
    Swal.fire({
      title: '¡Grupo actualizado!',
      text: 'El grupo se ha actualizado con éxito',
      icon: 'success',
      confirmButtonColor: '#00e07b',
      confirmButtonText: 'Aceptar',
      allowEscapeKey: false,
      allowEnterKey: false,
      allowOutsideClick: false
    }).then((result) => {
      if (result.value) {
        window.location.reload();
      }
    });
  }

  filterList($event: any) {
    const searchTerm = $event.target.value.toLowerCase();
    this.stationsGroup = this.stationsGroupOriginal.filter((d: any) => {
      return d['alias'].toLowerCase().includes(searchTerm);
    });
  }
}


