<div class="header-c">
    Volumen de Venta
    <epy-tooltip placement="top" style="width: 18px;display: inline-block;">
        <span class="difference" slot="trigger">
            <i class="fa fa-info-circle info" aria-hidden="true"></i>
        </span>
        Promedio de litros diarios vendidos por estación.
    </epy-tooltip>
    - Margen $
    <epy-tooltip placement="top" style="width: 18px;display: inline-block;">
        <span class="difference" slot="trigger">
            <i class="fa fa-info-circle info" aria-hidden="true"></i>
        </span>
        Promedio de margen de ganancia diario calculado por estación (Precio de Venta vs Precio de Compra).
    </epy-tooltip>
    &nbsp;
    <input type="checkbox" id="checkbox-tooltip" [(ngModel)]="showTooltip" (ngModelChange)="setData()"
        *ngIf="period !== 'day'">
    <label style="font-size: 13px;margin-bottom: 0;margin-left: .2%;margin-right: 2%;" for="checkbox-tooltip"
        *ngIf="period !== 'day'">Ver Dif y Dif %</label>
    <div style="width: 100px;display: inline-block;" *ngIf="rol === 'Dios'">
        <ngx-select class="volume-type" [allowClear]="false" [items]="items" optionValueField="value" optionTextField="name"
            [(ngModel)]="statisticSelected" placeholder="Promedio" (select)="changeVariable($event)">

            <ng-template ngx-select-option-not-found let-input>
                "{{input}}" no encontrada
            </ng-template>

        </ngx-select>
    </div>
    &nbsp;
    <input type="checkbox" id="checkbox-scale" [(ngModel)]="showScale" (ngModelChange)="setData()"
        *ngIf="productType !== 'total'"> <label style="font-size: 13px;margin-bottom: 0;margin-left: .2%;"
        for="checkbox-scale" *ngIf="productType !== 'total'">Escala desde 0</label>
</div>
<div *ngIf="reqst===1" class="text-center loader-container">
    <div class="spinner-grow align-middle text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>

<div [ngClass]="{
  'flex-item': ((period === 'week' && (weeks === 8 || weeks === 12)) || period === 'month' || (period === 'day' && (days === 15 || days === 30))) && !station,
  'flex-right': !(period === 'week' && (weeks === 8 || weeks === 12)) && !(period === 'day' && (days === 15 || days === 30)) || station
}" *ngIf="reqst===2">
    <div>
        <highcharts-chart id="mychart3" [Highcharts]="Highcharts" [options]="chartOptions"
            [ngStyle]="{ 'height': station || period !== 'month' && !(period === 'week' && (weeks === 8 || weeks === 12)) && !(period === 'day' && (days === 15 || days === 30)) ? 'auto': '310px'}" style="width: 100%;display: block;"></highcharts-chart>
    </div>
</div>