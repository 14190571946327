<ng-container *ngIf="reqst === 1">
    <div class="header-c">
        <strong>
            {{type}} por Hora del Día
        </strong>
    </div>
    <div>
        <highcharts-chart [Highcharts]="Highcharts" [options]="chartOptions"
            style="width: 100%; height: 310px; display: block;">
        </highcharts-chart>
    </div>    
</ng-container>

<div *ngIf="reqst === 0 " class="text-center loader-container">
    <div class="spinner-grow align-middle text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>