import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-station',
  templateUrl: './select-station.component.html',
  styleUrls: ['./select-station.component.scss']
})
export class SelectStationComponent implements OnInit, OnDestroy {

  idGroup = '60afa99655f4ee369d1df409';
  idGroupSub$!: Subscription;
  public items: any[] = [];
  public stationSelected: any = [];
  public ngxDisabled = true;
  stationSelected$: Subscription;
  rol;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService,
    private gasprecioService: GasprecioService
  ) {
    this.rol = localStorage.getItem('rol');
    this.stationSelected = localStorage.getItem('idStation') !== 'null' ? localStorage.getItem('idStation') : null;
    this.idGroup = (this.rol === 'Dios') ? localStorage.getItem('group') || '60afa99655f4ee369d1df409' : localStorage.getItem('group');
    this.idGroupSub$ = this.sharedService.sharedGroup.subscribe(idGroup => {
      if (idGroup !== this.idGroup && idGroup !== '') {
        this.idGroup = idGroup;
        this.getStationsGroup();
      }
    });

    this.stationSelected$ = this.sharedService.sharedStation.subscribe(idStation => {
      if (idStation !== this.stationSelected && idStation !== '') {
        this.stationSelected = idStation;
      }
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnInit(): void {
    this.getStationsGroup();
  }

  ngOnDestroy() {
    this.idGroupSub$.unsubscribe();
    this.stationSelected$.unsubscribe();
  }

  getStationsGroup() {
    this.items = [];
    this.gasprecioService.getStationsGroup(this.idGroup).subscribe((data: any) => {
      const stations: any = data;
      stations.forEach((element: any) => {
        this.items.push({ name: element.alias, value: element._id });
      });
      this.ngxDisabled = false;
    });
  }

  changeStation(event: any) {
    this.stationSelected = event;
    this.sharedService.nextStation = this.stationSelected;
    localStorage.setItem('idStation', this.stationSelected);
  }

}
