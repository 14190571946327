import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-cluster',
  templateUrl: './select-cluster.component.html',
  styleUrls: ['./select-cluster.component.scss']
})
export class SelectClusterComponent implements OnInit, OnDestroy {

  @Input() dashboard: any;
  @Input() name: any = 'Imagén';
  first = true;
  idGroup;
  idGroup$: Subscription;
  idZone = '';
  loading = false;
  public cluster: any = [];
  public items: any[] = [{ name: 'Sin Filtro', value: null }];
  public ngxDisabled = true;
  rol;
  selectedClusters;
  selectedClusters$: Subscription;
  showMegagas = false;
  zone$!: Subscription;
  zoneDashboard$!: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private gasprecioService: GasprecioService,
    private sharedService: SharedService
  ) {
    this.rol = localStorage.getItem('rol');
    this.idGroup = (this.rol === 'Dios') ? localStorage.getItem('group') || '60afa99655f4ee369d1df409' : localStorage.getItem('group');
    this.showMegagas = this.idGroup === '666cb6528c251f2c5d6204b5' ? true : false;
    this.idGroup$ = sharedService.sharedGroup.subscribe((idGroup: string) => {
      if (idGroup !== this.idGroup) {
        this.first = true;
        this.idGroup = idGroup;
        this.idZone = '';
        if (!this.loading) {
          this.getClusters();
        }
      }
    });

    this.zoneDashboard$ = sharedService.sharedZoneDashboard.subscribe((zone: any) => {
      if (zone !== this.idZone && this.dashboard) {
        this.idZone = zone;
        if (!this.loading) {
          this.getClusters();
        }
      }
    });

    this.zone$ = sharedService.sharedZone.subscribe((zone: any) => {
      if (zone !== this.idZone && !this.dashboard) {
        this.idZone = zone;
        if (!this.loading) {
          this.getClusters();
        }
      }
    });

    this.selectedClusters$ = this.sharedService.sharedSelectedComplementClusters.subscribe(selectedClusters => {
      if (selectedClusters !== this.selectedClusters) {
        this.selectedClusters = selectedClusters;
      }
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnInit(): void { }

  ngOnDestroy() {
    this.idGroup$.unsubscribe();
    this.zoneDashboard$.unsubscribe();
    this.zone$.unsubscribe();
    this.selectedClusters$.unsubscribe();
  }

  getClusters() {
    this.items = [
      { name: 'Sin Filtro', value: null }
    ];
    this.first = false;
    this.loading = true;
    console.log(this.idZone)
    setTimeout(() => {
      this.gasprecioService.getClusters(this.idGroup, 'clusters', this.idZone).subscribe({
        next: (data: any) => {
          data.map((group) => {
            this.items.push({ name: group['name'], value: group['_id'] });
          });
        },
        complete: () => {
          this.ngxDisabled = false;
          this.loading = false;
        }
      });
    }, 200);
  }

  changeCluster(event: any) {
    this.selectedClusters = event;
    this.sharedService.nextSelectedComplementClusters = this.selectedClusters;
  }
}
