import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../enviroments/enviroments.prod';
import { map } from 'rxjs/operators';
import { AuthService } from '../../../services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class StationService {

    private endpoint1 = environment.apiUrl + '/v1';
    public idStation;
    public idCre: any;
    public rol: any;

    constructor(
        private http: HttpClient,
        public authenticationService: AuthService,
    ) {
        this.idStation = localStorage.getItem('idStation') ? localStorage.getItem('idStation') : this.authenticationService.idStation;
    }

    getProfile() {
        return this.http.get<any>(`${this.endpoint1}/crm/users/profile`)
            .pipe(map(user => {
                return user;
            })
        );
    }

    getStationProfile() {
        return this.http.get<any>(`${this.endpoint1}/crm/stations/${this.idStation}`)
            .pipe(map(station => {
                return station;
            })
        );
    }

    updateStationProfile(name: any, location: any, website: any, email: any, telephone: any, type: any, inauguration: any, dispensaries: any, brand: any) {

        const data = { 'name': name, 'location': location, 'website': website, 'email': email, 'phone': telephone, 'type_conv_store': type, 'inauguration': inauguration, 'num_dispensaries': dispensaries, 'brand': brand };

        return this.http.put<any>(`${this.endpoint1}/crm/stations/${this.idStation}`, data)
            .pipe(map(station => {
                return station;
            })
        );
    }


    uploadChargeRecord(record: any) {

        const formData = new FormData();
        formData.append('record', record);

        return this.http.post<any>(`${this.endpoint1}/crm/stations/${this.idStation}/charge_record`, formData)
            .pipe(map(resp => {
                return resp;
            })
        );
    }

    overwriteChargeRecord(record: any) {

        const formData = new FormData();
        formData.append('record', record);

        return this.http.put<any>(`${this.endpoint1}/crm/stations/${this.idStation}/charge_record`, formData)
            .pipe(map(resp => {
                return resp;
            })
        );
    }


    getBlacklist() {
        return this.http.get<any>(`${this.endpoint1}/crm/stations/${this.idStation}/blacklist`)
            .pipe(map(data => {
                return data.blacklist;
            })
        );
    }

    addUserBlacklist(name: any, rfc: any) {

        const data = { 'name': name, 'rfc': rfc };

        return this.http.post<any>(`${this.endpoint1}/crm/stations/${this.idStation}/blacklist`, data)
            .pipe(map(user => {
                return user.blacklist;
            })
        );
    }

    updateUserBlacklist(id: any, name: any, rfc: any) {

        const data = { 'name': name, 'rfc': rfc };

        return this.http.put<any>(`${this.endpoint1}/crm/stations/${this.idStation}/blacklist/${id}`, data)
            .pipe(map(user => {
                return user.blacklist;
            })
        );
    }

    deleteUserBlacklist(id: any) {

        return this.http.delete<any>(`${this.endpoint1}/crm/stations/${this.idStation}/blacklist/${id}`)
            .pipe(map(user => {
                return user.blacklist;
            })
        );
    }

    getUsers() {
        return this.http.get<any>(`${this.endpoint1}/crm/stations/${this.idStation}/users`)
            .pipe(map(data => {
                return data.users;
            })
        );
    }

    addUser(name: any, email: any, phone: any, rol: any) {

        const data = { 'name': name, 'email': email, 'phone': phone, 'rol': rol };

        return this.http.post<any>(`${this.endpoint1}/crm/stations/${this.idStation}/users`, data)
            .pipe(map(resp => {
                return resp.users;
            })
        );
    }

    updateUser(id: any, name: any, email: any, phone: any, rol: any, notifications: any) {

        const data = { 'name': name, 'email': email, 'phone': phone, 'rol': rol, 'notifications': notifications };

        return this.http.put<any>(`${this.endpoint1}/crm/stations/${this.idStation}/users/${id}`, data)
            .pipe(map(user => {
                return user.userslist;
            })
        );
    }

    deleteUser(id: any) {
        return this.http.delete(`${this.endpoint1}/crm/stations/${this.idStation}/users/${id}`)
            .pipe(map(data => {
                return data;
            })
        );
    }

}