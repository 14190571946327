<ng-container *ngIf="!loading">
    <div class="header-c">
      <strong>
        Scorecard Mensual - <span class="text-capitalize" [ngClass]="{
          'text-regular': product === 'regular',
          'text-premium': product === 'premium',
          'text-diesel': product === 'diesel'
        }"> {{ product }} </span>
      </strong>
    </div>
    <div [ngClass]="{'flex-item': product === 'premium' }">
      <table class="scorecard-table" >
        <thead>
          <tr>
            <th>CARGA</th>
            <th>LITROS</th>
            <th>AUTOS</th>
            <th>INGRESO</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div class="contenedor">
                <div class="elemento-1">CHICA</div>
                <div class="elemento-2">REGULAR</div>
                <div class="elemento-3">GRANDE</div>
                <div class="elemento-4">MUY GRANDE</div>
              </div>
            </td>
            <td rowspan="3">
              <div>
                <highcharts-chart [Highcharts]="Highcharts" [options]="chartOptionsLiters"
                  style="width: 100%; display: block"></highcharts-chart>
              </div>
            </td>
            <td rowspan="4">
              <div>
                <highcharts-chart [Highcharts]="Highcharts" [options]="chartOptionsCount"
                  style="width: 100%; display: block"></highcharts-chart>
              </div>
            </td>
            <td rowspan="4">
              <div>
                <highcharts-chart [Highcharts]="Highcharts" [options]="chartOptionsAmount"
                  style="width: 100%; display: block"></highcharts-chart>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot class="tfoot-black">
          <tr class="text-left">
            <td class="text-center">
              <strong> TOTAL </strong>
            </td>
            <td>
              &nbsp;&nbsp;&nbsp;
              <span *ngIf="total.liters > 0">
                <strong> {{ total.liters | number : "1.0-0" }} </strong>
              </span>
            </td>
            <td>
              &nbsp;&nbsp;&nbsp;
              <span *ngIf="total.count > 0">
                <strong> {{ total.count | number : "1.0-0" }} </strong>
              </span>
            </td>
            <td>
              &nbsp;&nbsp;&nbsp;
              <span *ngIf="total.amount > 0">
                <strong> $ {{ total.amount | number : "1.0-0" }} </strong>
              </span>
    
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  
  </ng-container>
  
  <div class="row" style="margin: 10px" *ngIf="loading">
    <app-table-loader style="width: 100%" [rows]="9"></app-table-loader>
  </div>