import { Component, Input, OnDestroy } from "@angular/core";
import { combineLatest, Subject, Subscription, takeUntil } from "rxjs";
import { GasprecioService } from "../../../gasprecio/services/gasprecio.service";
import { SharedService } from "../../../gasprecio/services/shared.service";
import {
  Router,
  Event as NavigationEvent,
  NavigationEnd,
} from "@angular/router";
import Highcharts from "highcharts";
import { GroupIds } from "src/app/modules/gasprecio/enums/groups-ids.enum";
import moment from "moment";

@Component({
  selector: "app-scorecard-by-product",
  templateUrl: "./scorecard-by-product.component.html",
  styleUrls: ["./scorecard-by-product.component.scss"],
})
export class ScorecardByProductComponent implements OnDestroy {

  @Input() product: any = "regular";
  @Input() station: any = false;
  chartOptions: Highcharts.Options;
  data = [];
  date = moment('2024-09-01');
  event$!: Subscription;
  Highcharts = Highcharts;
  hours = [];
  idGroup: GroupIds | string = GroupIds.Pa;
  loading = false;
  private destroy$ = new Subject<void>();
  period = "day";
  reqst = 0;
  rol;
  route;
  stationId: any;
  zone;
  total;
  changeZone = false;
  chartOptionsLiters: Highcharts.Options;
  chartOptionsCount: Highcharts.Options;
  chartOptionsAmount: Highcharts.Options;

  constructor(
    private gasprecioService: GasprecioService,
    private sharedService: SharedService,
    private router: Router
  ) {
    this.rol = localStorage.getItem("rol");
    this.idGroup = this.rol === "Dios" ? localStorage.getItem("group") || GroupIds.Pa : localStorage.getItem("group");
    this.event$ = this.router.events.subscribe((event: NavigationEvent) => {
      if (event instanceof NavigationEnd) {
        this.route = /[^/]*$/.exec(event.url)[0];
      }
    });

    combineLatest([
      this.sharedService.sharedGroup,
      this.sharedService.sharedZoneHeatMap,
      this.sharedService.sharedStationSelected,
      this.sharedService.sharedSelectedMonth,
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([idGroup, zone, stationId, period]) => {
        if (idGroup !== this.idGroup) {
          this.idGroup = idGroup;
        }

        if (this.zone !== zone) {
          if (this.zone !== undefined) {
            this.changeZone = true;
          }
          this.zone = zone;
        }

        if (this.stationId !== stationId) {
          this.stationId = stationId;
        }

        if (period !== this.date) {
          this.date = period;
        }

        if (!this.loading) {
          this.getHeatMapScorecard();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getHeatMapScorecard() {
    this.loading = true;
    this.reqst = 0;
    this.data = [];
    this.hours = [];
    setTimeout(() => {
      const url = this.station ? this.gasprecioService.getHeatMapScorecardByStation(this.idGroup, this.product, this.date )  : 
      this.stationId === 'zone' ?  this.gasprecioService.getHeatMapScorecard(this.idGroup, this.zone, this.product, this.date, true) : 
      this.gasprecioService.getHeatMapScorecard(this.idGroup, this.stationId, this.product, this.date);
      
      url.subscribe({
        next: (response: any) => {
          this.total = response.total;
          const litersData = [
            response.small.liters  || 0,
            response.medium.liters || 0,
            response.large.liters || 0,
            response.longLarge.liters || 0
          ];
          const countData = [
            response.small.count || 0,
            response.medium.count || 0,
            response.large.count || 0,
            response.longLarge.count || 0
          ];
          const amountData = [
            response.small.amount || 0,
            response.medium.amount || 0,
            response.large.amount || 0,
            response.longLarge.amount || 0
          ];
          this.setChartData(litersData, countData, amountData);
        },
        complete: () => {
          this.reqst = 1;
        },
        error: () => {
          this.loading = false;
          this.reqst = 2;
        },
      });
    }, 2000);
  }

  setChartData(litersData: number[], countData: number[], amountData: number[]) {
    const colorP = this.product === 'regular' ? '#159546' : this.product === 'premium' ? '#ea0000' : this.product === 'diesel' ? '#1e2228' : '';
    const colorT = this.product === 'regular' ? 'black' : this.product === 'premium' ? 'black' : this.product === 'diesel' ? 'white' : '';

    const commonOptions = {
      chart: {
        type: "bar",
        height: "250px",
      },
      xAxis: {
        categories: [
          "CHICA",
          "REGULAR",
          "GRANDE",
          "MUY GRANDE"
        ],
        labels: {
          enabled: false,
        },
        gridLineWidth: 0
      },
      yAxis: {
        min: 0,
        title: {
          text: null,
        },
        labels: {
          enabled: false,
        },
        gridLineColor: "transparent"
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
            inside: true,
            formatter: function() {
              return this.y ? this.y.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : '';
            },
            align: 'left',
            verticalAlign: 'middle',
            y: -1,
            style: {
                color: colorT,
                fontSize: '13px',
                fontWeight: 'bold'
            }
          },
        }
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.y:,.0f}</b>'
      },
      exporting: {
        enabled: false
      }
    };
  
    this.chartOptionsLiters = {
      ...commonOptions,
      title: { text: "Litros", style: {fontSize: '0'} },
      xAxis: {
        ...commonOptions.xAxis
      },
      series: [{ name: "Litros", type: "bar", data: litersData, color: colorP, }]
    };
  
    this.chartOptionsCount = {
      ...commonOptions,
      title: { text: "Autos", style: {fontSize: '0'}  },
      series: [{ name: "Autos", type: "bar", data: countData, color: colorP, }],
    };
  
    this.chartOptionsAmount = {
      ...commonOptions,
      title: { text: "Ingreso", style: {fontSize: '0'}  },
      series: [{ name: "Ingreso", type: "bar", data: amountData, color: colorP, }],
      tooltip: {
        pointFormat: '{series.name}: <b>$ {point.y:,.0f}</b>'
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
            inside: true,
            formatter: function() {
              return this.y ? '$ ' + this.y.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : '';
            },
            align: 'left',
            verticalAlign: 'middle',
            style: {
                color: colorT,
                fontSize: '13px',
                fontWeight: 'bold'
            }
          },
        }
      }
    };
  
    this.loading = false;
  }
}
