import { Component, Input, OnDestroy } from "@angular/core";
import { combineLatest, Subject, Subscription, takeUntil } from "rxjs";
import { GasprecioService } from "../../../gasprecio/services/gasprecio.service";
import { SharedService } from "../../../gasprecio/services/shared.service";
import {
  Router,
  Event as NavigationEvent,
  NavigationEnd,
} from "@angular/router";
import Highcharts from "highcharts";
import { GroupIds } from "src/app/modules/gasprecio/enums/groups-ids.enum";
import moment from "moment";

@Component({
  selector: "app-heat-map-shift",
  templateUrl: "./heat-map-shift.component.html",
  styleUrls: ["./heat-map-shift.component.scss"],
})
export class HeatMapShiftComponent implements OnDestroy {

  @Input() variable = "transactions";
  @Input() type = "Autos";
  @Input() shift: any = 1;
  @Input() station: any = false;
  chartOptions: Highcharts.Options;
  data = [];
  date = moment().month(8).date(1).format('YYYY-MM-DD');
  event$!: Subscription;
  Highcharts = Highcharts;
  hours = [];
  idGroup: GroupIds | string = GroupIds.Pa;
  loading = false;
  private destroy$ = new Subject<void>();
  productType = "regular";
  period = "day";
  reqst = 0;
  rol;
  route;
  stationId: any;
  zone;
  changeZone = false;

  constructor(
    private gasprecioService: GasprecioService,
    private sharedService: SharedService,
    private router: Router
  ) {
    this.rol = localStorage.getItem("rol");
    this.idGroup = this.rol === "Dios" ? localStorage.getItem("group") || GroupIds.Pa : localStorage.getItem("group");
    this.event$ = this.router.events.subscribe((event: NavigationEvent) => {
      if (event instanceof NavigationEnd) {
        this.route = /[^/]*$/.exec(event.url)[0];
      }
    });

    combineLatest([
      this.sharedService.sharedGroup,
      this.sharedService.sharedZone,
      this.sharedService.sharedStationSelected,
      this.sharedService.sharedSelectedMonth,
      this.sharedService.sharedProductTypeTotal,
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([idGroup, zone, stationId, period, productType]) => {
        if (idGroup !== this.idGroup) {
          this.idGroup = idGroup;
        }

        if (this.zone !== zone) {
          if (this.zone !== undefined) {
            this.changeZone = true;
          }
          this.zone = zone;
        }

        if (this.stationId !== stationId) {
          this.stationId = stationId;
        }

        if (period !== this.date) {
          this.date = period;
        }

        if (this.productType !== productType) {
          this.productType = productType;
        }

        if (!this.loading) {
          this.getHeatMapShift();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getHeatMapShift() {
    this.loading = true;
    this.reqst = 0;
    this.data = [];
    this.hours = [];
    setTimeout(() => {
      const url = this.station ? this.gasprecioService.getHeatMapShiftByStation(this.idGroup,  this.variable, this.productType, this.shift,  this.date) : this.gasprecioService.getHeatMapShift(this.idGroup, this.stationId, this.variable, this.productType, this.shift,  this.date);
      url.subscribe({
        next: (data: any) => {
          this.data = data["data_formatted"];
          this.hours = data["hours"];
          this.setData();
        },
        complete: () => {
          this.reqst = 1;
        },
        error: () => {
          this.loading = false;
          this.reqst = 2;
        },
      });
    }, 400);
  }

  setData() {
    this.chartOptions = {
      credits: {
        enabled: false,
      },
      chart: {
        type: "heatmap",
        spacingTop: 30,
        spacingRight: 14,
        spacingBottom: 10,
        spacingLeft: 0
      },
      title: {
        text: `Mapa de Calor - Turno ${this.shift}`,
        style: {
          fontSize: '0'
        }
      },
      exporting: {
        filename:  `Mapa de Calor - Turno ${this.shift}`,
        sourceWidth: 1200,
        sourceHeight: 800,
      },
      navigation: {
        buttonOptions: {
          align: 'right',
          y: -28
        }
      },
      xAxis: {
        categories: [
          "Lunes",
          "Martes",
          "Miércoles",
          "Jueves",
          "Viernes",
          "Sábado",
          "Domingo",
        ],
        title: {
          text: "Días de la Semana",
          style: {
            fontSize: "0",
          },
        },
        opposite: true,
        lineWidth: 26,
        offset: 13,
        lineColor: "rgba(27, 26, 37, 0.1)",
        labels: {
          rotation: 0,
          y: 20,
          style: { fontWeight: "bold" },
        },
      },
      yAxis: {
        categories: this.hours,
        title: {
          text: "Horas del día",
          style: {
            fontSize: "0",
          },
        },
        reversed: true,
      },
      colorAxis: {
        min: 0,
        minColor: "#86CEB3",
        maxColor: "#E87B79", // Color para valores altos
      },
      legend: {
        enabled: false
      },
      tooltip: {
        formatter: function () {
          return `Día: <b>${this.series.xAxis.categories[this.point.x]}</b><br>
                  Hora: <b>${this.series.yAxis.categories[this.point.y]}</b><br>
                  Autos: <b>${this.point.value?.toLocaleString()}</b>`;
        },
      },
      series: [
        {
          type: "heatmap",
          name: `${this.type} en una semana`,
          borderWidth: 1,
          borderColor: 'rgba(255, 255, 255, 1)',
          data: this.gasprecioService.processDataForHeatmap(this.data), // Genera datos en función del turno
          dataLabels: {
            enabled: true,
            color: "#000000",
            style: {
              textOutline: "none" // Esto quita el borde blanco alrededor del texto
            }
          },
        },
      ],
    };

    setTimeout(() => {
      this.loading = false;
    }, 100);
  }

  
}
