import { Component, Input, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import { GroupIds } from 'src/app/modules/gasprecio/enums/groups-ids.enum';

@Component({
  selector: 'app-select-station-zone',
  templateUrl: './select-station-zone.component.html',
  styleUrls: ['./select-station-zone.component.scss']
})
export class SelectStationZoneComponent implements OnDestroy {

  @Input() addEmptyStation = true;
  idGroup: GroupIds | string = GroupIds.Pa;
  idGroupSub$!: Subscription;
  public items: any[] = [];
  public stationSelected: any = [];
  public ngxDisabled = true;
  stationSelected$: Subscription;
  zoneSelected$: Subscription;
  zoneSelected;
  rol;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService,
    private gasprecioService: GasprecioService
  ) {
    this.rol = localStorage.getItem('rol');
    this.idGroup = (this.rol === 'Dios') ? localStorage.getItem('group') || GroupIds.Pa : localStorage.getItem('group');
    this.idGroupSub$ = this.sharedService.sharedGroup.subscribe(idGroup => {
      if (idGroup !== this.idGroup && idGroup !== '') {
        this.idGroup = idGroup;
        this.getStationsGroup();
      }
    });

    this.stationSelected$ = this.sharedService.sharedStation.subscribe(idStation => {
      if (idStation !== this.stationSelected && idStation !== '') {
        this.stationSelected = idStation;
      }
    });

    this.zoneSelected$ = this.sharedService.sharedZone.subscribe(zone => {
      if (zone !== this.zoneSelected) {
        this.zoneSelected = zone;
        this.getStationsGroup();
      }
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnDestroy() {
    this.idGroupSub$.unsubscribe();
    this.stationSelected$.unsubscribe();
    this.zoneSelected$.unsubscribe();
  }

  private updateStationItems(stations: any[]) {
    this.items = stations.map(element => ({
      name: this.gasprecioService.capitalizeFirstLetter(element.alias),
      value: element._id
    }));

    if (this.addEmptyStation) {
      this.items.unshift({
        name: 'Solo Zona',
        value: 'zone'
      });
    }
  }
  
  getStationsGroup() {
    setTimeout(() => {
      this.items = [];
      this.gasprecioService.getStationsGroupZone(this.idGroup, this.zoneSelected).subscribe((data: any) => {
        this.updateStationItems(data);
        this.ngxDisabled = false;
    
        if (this.items.length > 0) {
          this.stationSelected = this.items[0].value; // se seleccionará 'Sin estacion' si está al inicio
          this.sharedService.nextStationSelected = this.stationSelected;
        }
      });

      this.ngxDisabled = false;
    }, 100); 
  }

  changeStation(event: any) {
    this.stationSelected = event;
    this.sharedService.nextStationSelected = this.stationSelected;
  }

}
