import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-days',
  templateUrl: './select-days.component.html',
  styleUrls: ['./select-days.component.scss']
})
export class SelectDaysComponent implements OnDestroy, OnInit {

  @Input() customDays?: number[];
  @Input() default?: number;
  public items: any[] = [];

  public daysAgo: any = [];
  public ngxDisabled = false;
  daysAgo$: Subscription;

  constructor(
    public sanitizer: DomSanitizer,
    private sharedService: SharedService
    ) {
    this.daysAgo$ = this.sharedService.sharedDaysAgo.subscribe((daysAgo) => {
      this.daysAgo = daysAgo;
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnInit(): void {
    if (this.customDays?.length) {
      this.items = this.customDays.map((cd) => ({ value: cd, name: `Últ. ${cd} días` }));
    } else {
      this.items = [
        { value: 7, name: 'Últ. 7 días' },
        { value: 14, name: 'Últ. 14 días' },
        { value: 30, name: 'Últ. 30 días' },
        { value: 99, name: 'Mes Actual' },
        { value: 0, name: 'Mes Anterior' }
      ];
    }
    if (this.default) this.changeDaysAgo(this.default);
  }

  ngOnDestroy() {
    this.daysAgo$.unsubscribe();
  }

  changeDaysAgo(event: any) {
    this.daysAgo = event;
    this.sharedService.nextDaysAgo = this.daysAgo;
  }
}
