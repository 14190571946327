import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';

@Component({
  selector: 'app-select-budget',
  templateUrl: './select-budget.component.html',
  styleUrls: ['./select-budget.component.scss']
})
export class SelectBudgetComponent implements OnInit, OnDestroy {

  @Input() budget: number;
  public items: any[] = [];
  public idGroup: any;
  public idBudget: any = [];
  public placeholder = 'Presupuesto';
  public ngxDisabled = true;
  id;
  idBudget$: Subscription;
  idGroup$: Subscription;
  rol;

  constructor(
    public sanitizer: DomSanitizer,
    private gasprecioService: GasprecioService,
    private sharedService: SharedService
  ) {
    this.rol = localStorage.getItem('rol');
    this.id = localStorage.getItem('id');
    this.idGroup = (this.rol === 'Dios') ? localStorage.getItem('group') || '60afa99655f4ee369d1df409' : localStorage.getItem('group');

    this.idGroup$ = sharedService.sharedGroup.subscribe((idGroup: string) => {
      if (idGroup !== this.idGroup) {
        this.idGroup = idGroup;
        this.getBudgets();
      }
    });

    this.idBudget$ = sharedService.sharedBudget.subscribe((idBudget: string) => {
      this.idBudget = idBudget;
    });
  }

  public style(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }

  ngOnInit(): void {
    switch (this.budget) {
      case 1:
        this.placeholder = 'Presupuesto 1';
        break;
      case 2:
        this.placeholder = 'Presupuesto 2';
        break;
      case 3:
        this.placeholder = 'Presupuesto 3';
        break;
      default:
        this.placeholder = 'Presupuesto';
        break;
    }
    this.getBudgets();
  }

  ngOnDestroy() {
    this.idBudget$.unsubscribe();
  }

  getBudgets() {
    this.gasprecioService.getBudgets(this.idGroup).subscribe((data: any) => {
      data.map((budget) => {
        this.items.push({ name: budget['name'], value: budget['_id'] });
      });
      if (this.items?.length > 0 && this.idBudget === '') {
        switch (this.budget) {
          case 1:
            this.idBudget = this.items[this.items.length - 1]['value'];
            this.sharedService.nextBudgetV1 = this.idBudget;
            break;
          case 2:
            this.idBudget = this.items[this.items.length - 2]['value'];
            this.sharedService.nextBudgetV2 = this.idBudget;
            break;
          case 3:
            this.idBudget = this.items[this.items.length - 3]['value'];
            this.sharedService.nextBudgetV3 = this.idBudget;
            break;
          default:
            this.idBudget = this.items[this.items.length - 1]['value'];
            break;
        }
      }
      this.ngxDisabled = false;
    });
  }

  changeBudget(event: any) {
    this.idBudget = event;
    switch (this.budget) {
      case 1:
        this.sharedService.nextBudgetV1 = this.idBudget;
        break;
      case 2:
        this.sharedService.nextBudgetV2 = this.idBudget;
        break;
      case 3:
        this.sharedService.nextBudgetV3 = this.idBudget;
        break;
      default:
        this.sharedService.nextBudget = this.idBudget;
        break;
    }
  }
}
