import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import { SelectItem } from '../../models/select-item.model';

export type Ranking = SelectItem;

@Component({
  selector: 'app-select-ranking',
  templateUrl: './select-ranking.component.html',
  styleUrls: ['./select-ranking.component.scss']
})
export class SelectRankingComponent implements OnInit, OnDestroy {
  items: Ranking[];
  sharedSelectedRanking$: Subscription;
  sharedRankings$: Subscription;
  selectedRanking!: string;

  constructor(
    private sharedService: SharedService
  ) {
    this.sharedSelectedRanking$ = this.sharedService.sharedSelectedRanking.subscribe((ranking: string) => {
      this.selectedRanking = ranking;
    });

    this.sharedRankings$ = this.sharedService.sharedRanking.subscribe((rankings: Ranking[]) => {
      this.items = rankings;
    });
  }

  ngOnInit(): void {
    this.getDatesFromToday();
    this.setSelectedRanking(this.items[0].value);
    this.setRankings(this.items);
  }

  ngOnDestroy(): void {
    this.sharedService.setSharedRanking = [];
    this.sharedSelectedRanking$.unsubscribe();
    this.sharedRankings$.unsubscribe();
  }

  setSelectedRanking(value: string): void {
    this.selectedRanking = value;
    this.sharedService.nextSelectedRanking = this.selectedRanking;
  }

  setRankings(rankings: Ranking[]): void {
    this.sharedService.setSharedRanking = rankings;
  }

  getDatesFromToday(): void {
    const today = new Date();
    const year = today.getFullYear();
    const months = [
      'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun',
      'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'
    ];

    for (let i = today.getMonth(); i >= 0; i--) {
      const month = months[i];
      this.items.push({ name: `Ranking ${month}. ${year % 100}`, value: `${year}-${i + 1 < 10 ? `0${i + 1}` : i + 1}-01` });
    }
  }

  changeRanking(event: string): void {
    this.selectedRanking = event;
    this.setSelectedRanking(this.selectedRanking);
  }
}
