import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { GasprecioService } from 'src/app/modules/gasprecio/services/gasprecio.service';
import { SharedService } from 'src/app/modules/gasprecio/services/shared.service';
import { Router, Event as NavigationEvent, NavigationEnd } from '@angular/router';
import Highcharts from 'highcharts';

@Component({
  selector: 'app-chart-tickets',
  templateUrl: './chart-tickets.component.html',
  styleUrls: ['./chart-tickets.component.scss']
})
export class ChartTicketsComponent implements OnInit, OnDestroy {

  @Input() station: any = false;
  date: any;
  days = 30;
  days$!: Subscription;
  idGroup = '60afa99655f4ee369d1df409';
  idGroup$!: Subscription;
  importants;
  importants$: Subscription;
  months = 12;
  months$!: Subscription;
  event$!: Subscription;
  period = 'day';
  period$!: Subscription;
  productType = 'regular';
  productType$!: Subscription;
  purchaseMargin;
  reqst = 0;
  route;
  rol;
  showScale = true;
  showRendichicas = false;
  ticket: any;
  ticketCash: any;
  weeks = 12;
  weeks$!: Subscription;
  zoneSelected = null;
  zoneSelected$: Subscription;
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {
    credits: {
      enabled: false
    },
    chart: {
      type: 'line'
    },
    title: {
      text: `Ticket Promedio por transacción`,
      style: {
        fontSize: '0'
      }
    },
    xAxis: {
      categories: [],
      gridLineWidth: 1,
      gridLineDashStyle: 'LongDash',
      title: {
        text: 'Fecha',
        style: {
          fontSize: '0'
        }
      },
      labels: {
        style: {
          fontSize: '11px',
        },
        rotation: -60,
        y: 14
      }
    },
    yAxis: [
      {
        title: {
          text: 'Ticket Promedio lts',
          style: {
            fontSize: '0'
          }
        },
        labels: {
          format: '{value:,.0f} lts.',
          style: {
            fontSize: '12px'
          }
        },
        tickAmount: 10,
        gridLineDashStyle: 'LongDash'
      },
      {
        title: {
          text: 'Ticket Promedio lts',
          style: {
            fontSize: '0'
          }
        },
        labels: {
          format: '$ {value:.0f}',
          style: {
            fontSize: '12px'
          }
        },
        opposite: true,
        tickAmount: 10,
        gridLineDashStyle: 'LongDash'
      }
    ],
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'top',
      itemDistance: 10,
      y: 1,
      padding: 1,
      itemMarginTop: 1,
      itemMarginBottom: 1,
      itemHoverStyle: {
        color: '#00E07B',
      }
    },
    tooltip: {
      split: true
    },
    plotOptions: {
      series: {
        animation: {
          duration: 1000
        },
        marker: {
          radius: 2
        },
        lineWidth: 1
      }
    },
    exporting: {
      filename: `Ticket Promedio por transacción`,
      sourceWidth: 1200,
      sourceHeight: 800,
    },
    navigation: {
      buttonOptions: {
        align: 'right',
        y: -10
      }
    },
    series: []
  };

  constructor(
    private gasprecioService: GasprecioService,
    private sharedService: SharedService,
    private router: Router
  ) {
    this.event$ = this.router.events.subscribe((event: NavigationEvent) => {
      if (event instanceof NavigationEnd) {
        this.route = /[^/]*$/.exec(event.url)[0];
      }
    });
    this.rol = localStorage.getItem('rol');
    this.idGroup = (this.rol === 'Dios') ? localStorage.getItem('group') || '60afa99655f4ee369d1df409' : localStorage.getItem('group');
    this.showRendichicas = this.idGroup === '615efe30b318ce63b8971986' ? true : false;
    this.productType$ = this.sharedService.sharedProductTypeTotal.subscribe(productType => {
      if (this.productType !== productType) {
        this.productType = productType;
        if (this.reqst > 1) {
          this.setData();
        }
      }
    });
    this.idGroup$ = sharedService.sharedGroup.subscribe((idGroup: string) => {
      if (idGroup !== this.idGroup) {
        this.idGroup = idGroup;
        this.showRendichicas = this.idGroup === '615efe30b318ce63b8971986' ? true : false;
        if (this.reqst > 1) {
          this.zoneSelected = '';
          this.getDashboardAvgTransactionsStation();
        }
      }
    });

    this.zoneSelected$ = sharedService.sharedZoneDashboard.subscribe((zone: string) => {
      if (zone === 'Total') {
        this.zoneSelected = null;
        if (zone !== this.zoneSelected) {
          this.getDashboardAvgTransactionsStation();
        }
      } else {
        if (zone !== this.zoneSelected) {
          this.zoneSelected = zone;
          this.getDashboardAvgTransactionsStation();
        }
      }
    });

    this.period$ = this.sharedService.sharedPeriodDashboard.subscribe(period => {
      if (period !== this.period) {
        this.period = period;
        if (this.reqst > 1) {
          this.getDashboardAvgTransactionsStation();
        }
      }
    });

    this.days$ = this.sharedService.sharedDaysDashboard.subscribe(days => {
      if (days !== this.days) {
        this.days = days;
        if (this.reqst > 1) {
          this.getDashboardAvgTransactionsStation();
        }
      }
    });

    this.weeks$ = this.sharedService.sharedWeeksAgo.subscribe(weeks => {
      if (weeks !== this.weeks) {
        this.weeks = weeks;
        if (this.reqst > 1) {
          this.getDashboardAvgTransactionsStation();
        }
      }
    });

    this.months$ = this.sharedService.sharedMonthsAgo.subscribe(months => {
      if (months !== this.months) {
        this.months = months;
        if (this.reqst > 1) {
          this.getDashboardAvgTransactionsStation();
        }
      }
    });

    this.importants$ = sharedService.sharedImportants.subscribe((importants: string) => {
      if (this.importants !== importants) {
        this.importants = importants;
        if (this.reqst > 1) {
          this.getDashboardAvgTransactionsStation();
        }
      }
    });
  }

  ngOnInit() {
    this.getDashboardAvgTransactionsStation();
  }

  ngOnDestroy(): void {
    this.productType$.unsubscribe();
    this.idGroup$.unsubscribe();
    this.zoneSelected$.unsubscribe();
    this.period$.unsubscribe();
    this.days$.unsubscribe();
    this.weeks$.unsubscribe();
    this.months$.unsubscribe();
    this.importants$.unsubscribe();
    this.event$.unsubscribe();
  }

  getDashboardAvgTransactionsStation() {
    this.reqst = 1;
    let typeValue = 'value';
    const periodMap = {
      'day': this.days,
      'week': this.weeks,
      'month': this.months
    };
    const n = periodMap[this.period];
    typeValue = (typeof n === 'number') ? 'value' : 'date';
    const url = this.station ? this.gasprecioService.getDashboardAvgTransactionsStation(this.period, typeValue, n) : this.gasprecioService.getDashboardAvgTransactions(this.idGroup, this.zoneSelected, this.period, this.importants, typeValue, n);
    url.subscribe({
      next: (data: any) => {
        const info = data;
        this.ticket = info['avg_transactions'];
        this.ticketCash = info['avg_transactions_cash'];
        this.setData();
      }, complete: () => {
        this.reqst = 2;
      }, error: () => {
        this.reqst = 3;
      }
    });
  }

  setData() {
    this.reqst = 1;
    const color = this.gasprecioService.getColorString(this.gasprecioService.colorsProducts[this.gasprecioService.getProductTypeIndex(this.productType)]);
    const colorLight = this.gasprecioService.getColorString(this.gasprecioService.colorsProductsLight[this.gasprecioService.getProductTypeIndex(this.productType)]);

    const minVolume = Math.min(...this.ticket
      .map((comp: any) => +comp[this.productType])
      .filter((value: number) => value !== null && value !== 0)
    );

    const ticketCashChartData: any = {
      name: 'Ticket Promedio $',
      data: this.ticketCash.map((v: any) => {
        if (v[this.productType] === null) {
          return null; // Devuelve null si el valor es null
        } else {
          return +v[this.productType]; // Devuelve el valor convertido a número
        }
      }),
      color: colorLight,
      dashStyle: 'ShortDot',
      lineWidth: 2,
      marker: { radius: 2 },
      tooltip: {
        pointFormatter: function () {
          return '<b>' + this.series.name + ': $ ' + this.y.toFixed(0) + '</b>';
        }
      },
      yAxis: 1
    };

    const ticketChartData: any = {
      name: 'Ticket Promedio lts',
      data: this.ticket.map((v: any) => {
        if (v[this.productType] === null) {
          return null; // Devuelve null si el valor es null
        } else {
          return +v[this.productType]; // Devuelve el valor convertido a número
        }
      }),
      color: color,
      lineWidth: 2,
      tooltip: {
        pointFormatter: function () {
          return '<b>' + this.series.name + ': ' + this.y.toFixed(2) + ' lts.</b>';
        }
      },
      yAxis: 0
    };

    const lineXaxisLegendGenerator = {
      day: (v: any) => this.gasprecioService.parseDateDM(v.date),
      week: (v: any) => v.date_alias,
      month: (v: any) => this.gasprecioService.parseDateM(v.date)
    };

    const lineXaxisLegend = this.ticket.map(lineXaxisLegendGenerator[this.period]);
    this.chartOptions['xAxis']['categories'] = lineXaxisLegend;
    this.chartOptions['yAxis'][0]['min'] = this.showScale ? 0 : minVolume;
    this.chartOptions.series = [ticketCashChartData, ticketChartData];
    setTimeout(() => {
      this.reqst = 2;
    }, 100);
  }

  generateImageChart() {
    const canvas8: any = document.getElementById('mychart8');
    const imgData8 = canvas8.toDataURL('image/png');
    this.sharedService.nextImgData8 = imgData8;
  }
}
